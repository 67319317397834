import { Injectable } from '@angular/core';
import { Notyf } from 'notyf';

@Injectable()
export class ServicioToastr {

  public notify: Notyf;

  constructor(

  ) {
    this.notify = new Notyf({duration: 4000, dismissible: true, ripple: true, position: {x: 'right', y: 'top'}});
  }

  showSuccess(text: string) {
    this.notify.success(text);
  }

  showError(text: string) {
    this.notify.error(text);
  }
}
