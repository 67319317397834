<header class="header bg-white">
  <nav class="navbar navbar-expand-lg navbar-light background-medprep pr-5 pl-3">

    <!-- LOGEADO -->
    <a *ngIf="this.identity" class="navbar-brand" [routerLink]="['content']" href="#">
      <img *ngIf="identity.cuenta[0].tipo == 'Gratis'" src="../assets/images/navbarlogo.png" width="auto" height="26px"
        alt="Medprep" loading="lazy">
      <img *ngIf="identity.cuenta[0].tipo == 'Medprep Pro'" src="../../../../assets/images/logoPro.png" width="auto"
        height="26px" alt="Medprep" loading="lazy">
      <img *ngIf="identity.cuenta[0].tipo == 'Medprep+'" src="../../../../assets/images/logoPlus.png" width="auto"
        height="26px" alt="Medprep" loading="lazy">
    </a>
    <!-- SIN LOGEADO -->
    <a *ngIf="!this.identity" class="navbar-brand" [routerLink]="['main-page']" href="#">
      <img src="../assets/images/navbarlogo.png" width="auto" height="26px" alt="Medprep" loading="lazy">
    </a>

    <div class="centrar-contenido ml-auto celular" *ngIf="this.cronometroInfo.activado">
      <div class="p-2 contador-container rounded">
        <span class="contador-font font-weight-bold" id="hms1"></span>
      </div>
    </div>

    <button class="navbar-toggler ml-auto" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse text-center" id="navbarSupportedContent">
      <ul class="navbar-nav">
        <div class="contenido-celular">
          <li *ngIf="this.identity" class="nav-item mx-3 nav-li-medprep letra-celular">
            <a class="nav-link" [routerLink]="['/content/profile-view']" routerLinkActive="router-link-active"
              href="#">Dashboard</a>
          </li>
          <li *ngIf="this.identity" class="nav-item mx-3 nav-li-medprep letra-celular">
            <a class="nav-link" [routerLink]="['/content/subjects']" routerLinkActive="router-link-active"
              href="#">Materias</a>
          </li>
          <li *ngIf="this.identity" class="nav-item mx-3 nav-li-medprep letra-celular">
            <a class="nav-link" [routerLink]="['/content/exam-stats']" routerLinkActive="router-link-active"
              href="#">Examen</a>
          </li>
          <li *ngIf="this.identity" class="nav-item mx-3 nav-li-medprep letra-celular">
            <a class="nav-link" [routerLink]="['/content/calendar']" routerLinkActive="router-link-active"
              href="#">Calendario</a>
          </li>
          <li *ngIf="this.identity" class="nav-item mx-3 nav-li-medprep letra-celular">
            <a class="nav-link" [routerLink]="['/content/profile-edit']" routerLinkActive="router-link-active"
              href="#">Perfil</a>
          </li>
          <hr *ngIf="this.identity">
        </div>
        <li class="nav-item mx-3 nav-li-medprep letra-celular">
          <a class="nav-link" [routerLink]="['/information/contact']" routerLinkActive="router-link-active"
            href="#">Contacto</a>
        </li>
        <li class="nav-item mx-3 nav-li-medprep letra-celular">
          <a class="nav-link" [routerLink]="['/information/prices']" routerLinkActive="router-link-active"
            href="#">Precios</a>
        </li>
        <li *ngIf="!this.identity" class="nav-item mx-3 nav-li-medprep botones-celular">
          <a class="nav-link letra-celular" [routerLink]="['/auth/login']" routerLinkActive="router-link-active"
            href="#">Iniciar
            sesión</a>
        </li>
        <li *ngIf="!this.identity" class="nav-item mx-3 nav-li-medprep botones-celular pt-3">
          <button class="btn btn-medprep text-center d-block mx-auto letra-celular" style="max-width: 40%"
            [routerLink]="['/auth/registry']">Registrarse</button>
        </li>
      </ul>
      <div class="centrar-contenido ml-auto escritorio" *ngIf="this.cronometroInfo.activado">
        <div class="p-2 contador-container rounded">
          <span class="contador-font font-weight-bold" id="hms"></span>
        </div>
      </div>
      <div class="dropdown text-dark celular" *ngIf="this.identity">
        <a class="text-dark nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false">
          <img *ngIf="this.identity" class="m-avatar" src="{{this.identity.avatar[0].url}}" alt="">
          <span>{{identity.nombre + ' ' + identity.apellido}}</span>
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a class="btn dropdown-item" [routerLink]="['content']" routerLinkActive="router-link-active">Mi perfil</a>
          <button class="btn dropdown-item" (click)="cerrarSesion()">Cerrar sesión</button>
        </div>
      </div>
    </div>
    <div class="d-flex">
      <div class="dropdown text-dark escritorio" *ngIf="this.identity">
        <a class="text-dark nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false">
          <img *ngIf="this.identity" class="m-avatar" src="{{this.identity.avatar[0].url}}" alt="">
          <span>{{identity.nombre + ' ' + identity.apellido}}</span>
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a class="btn dropdown-item" [routerLink]="['content']" routerLinkActive="router-link-active">Mi perfil</a>
          <button class="btn dropdown-item" (click)="cerrarSesion()">Cerrar sesión</button>
        </div>
      </div>
      <button *ngIf="!this.identity" class="btn nav-li-medprep background-medprep botones-escritorio"
        [routerLink]="['/auth/login']">Iniciar
        sesión</button>
      <button *ngIf="!this.identity" class="btn btn-medprep text-center botones-escritorio"
        [routerLink]="['/auth/registry']">Registrarse</button>
    </div>

  </nav>
</header>
