import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Usuario } from '../models/usuario';
import { GLOBAL } from './global';
import { ServicioUsuario } from './usuario.service';
import { RUTAS } from './enums/rutas.enum';

@Injectable()
export class ServicioTemas {
  public url: string;

  constructor(
    public _servicioUsuario: ServicioUsuario,
    public _http: HttpClient
  ) {
    this.url = GLOBAL.url + RUTAS.TEMA;
  }

  obtenerTemas(materia: String): Observable<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this._http.get(this.url + 'obtener-temas/' + materia, { headers: headers });
  }

  obtenerTemasPorId(materia: String): Observable<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this._http.get(this.url + 'obtener-temas-por-id/' + materia, { headers: headers });
  }

  guardarProgreso(idActividad: String, token): Observable<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', token);
    var data = {
      actividad: idActividad
    };
    return this._http.post(this.url + 'guardar-progreso', data, { headers: headers });
  }

  obtenerEstadisticas(token): Observable<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', token);

    return this._http.get(this.url + 'obtener-progreso', { headers: headers });
  }

  guardarTema(tema): Observable<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    let params = JSON.stringify(tema);

    return this._http.post(this.url + 'guardar-tema/', params, { headers: headers });
  }

  editarTema(nombre, materiaId): Observable<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    let params = {
      nombre: nombre
    }

    return this._http.put(this.url + 'editar-tema-nombre/' + materiaId, params, { headers: headers });
  }



}
