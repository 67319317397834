import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CompanyInfoRoutingModule } from './company-info-routing.module';

//COMPONENTS
import { AboutUsComponent } from './about-us/about-us.component';
import { PricesComponent } from './prices/prices.component';
import { ContactComponent } from './contact/contact.component';
import { PrivacyNoticeComponent } from './privacy-notice/privacy-notice.component';
import { FAQComponent } from './faq/faq.component';

@NgModule({
  declarations: [
    AboutUsComponent,
    PricesComponent,
    ContactComponent,
    PrivacyNoticeComponent,
    FAQComponent
  ],
  imports: [
    CommonModule,
    CompanyInfoRoutingModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [
    FAQComponent
  ]
})
export class CompanyInfoModule { }
