<div class="container-fluid" [ngClass]="{'exam-container': this.finalizado_flag}">
  <div class="w-100"
    [ngClass]="{'mx-0': this.finalizado_flag, 'row': this.finalizado_flag, 'exam-behavior': this.finalizado_flag}">
    <div class="w-100 h-100 centrar-contenido" style="min-height: 90vh;" *ngIf="this.loading">
      <div class="w-100 text-center">
        <h2 class="w-100 text-center mb-0">Cargando...</h2>
        <img src="../../../../../assets/images/loadingBalls.gif" class="img-fluid w-25 mx-auto" alt="">
      </div>
    </div>
    <div *ngIf="!this.loading" class="exam-view centrar-contenido py-3" [ngClass]="{'col-xl-9': this.finalizado_flag}">
      <div class="row">
        <div class="w-100 col-12" *ngIf="this.preguntasList && this.preguntasList.length > 0 && !this.finalizado_flag">
          <h1 class="w-100 text-center">{{this.preguntasList[indiceMaterias].materia}}</h1>
        </div>
        <div class="border p-5 col-12 mt-3 shadow question-container margen-celular">
          <section *ngIf="!finalizado_flag">
            <!-- ERROR MESSAGE -->
            <div class="alert alert-danger text-center" *ngIf="this.preguntasError" role="alert">
              {{this.preguntasError}}
            </div>
            <!-- END OF ERROR MESSAGE -->
            <h5 class="w-100 text-center"><button (click)="toggleFinalizar()" *ngIf="this.examenCompletado"
                class="btn icon-btn"><i class="fas fa-list-ul"></i></button> {{ indiceGeneral + 1 }}/
              {{this.totalPreguntas}}</h5>
            <div class="progress">
              <div #barraDeProgreso class="progress-bar progress-bar-exam" role="progressbar" style="width: 0%"
                aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
            <div class="content mt-5 mb-3" style="min-height: 30vh;">
              <!-- PREGUNTAS -->
              <!-- PREGUNTAS EXAMEN SIMULACRO Y MINO SIMULACRO -->
              <section *ngIf="this.preguntasList && this.preguntasList.length > 0">
                <div class="d-flex justify-content-center"
                  *ngIf="!this.pregunta_especial_flag; else preguntaEspecialTexto">
                  <ng-katex-paragraph [options]="{displayMode: true}" class="text-center"
                    [paragraph]="this.preguntasList[indiceMaterias].preguntas[indice].pregunta"></ng-katex-paragraph>
                </div>
                <ng-template #preguntaEspecialTexto>
                  <div class="pregunta-especial-contenedor"
                    *ngIf="!this.lectura_terminada_flag; else lectura_concluida">
                    <pre>{{ this.preguntasList[indiceMaterias].preguntaEspecial.preguntaEspecial.texto }}</pre>
                  </div>
                  <ng-template #lectura_concluida>
                    <ng-katex-paragraph [options]="{displayMode: true}" class="text-center"
                      [paragraph]="this.preguntasList[indiceMaterias].preguntaEspecial.preguntas[indice].pregunta"></ng-katex-paragraph>
                  </ng-template>
                </ng-template>
                <div *ngIf="this.preguntasList[indiceMaterias].preguntas[indice].imagen" class="w-100 text-center">
                  <img [src]="this.preguntasList[indiceMaterias].preguntas[indice].imagen[0].url" class="img-fluid">
                </div>
              </section>
              <!-- FIN DE PREGUNTAS EXAMEN SIMULACRO Y MINO SIMULACRO -->
              <!-- PREGUNTAS EXAMEN PERSONALIZADO -->
              <section *ngIf="this.preguntasExamenPersonalizadoList">
                <div class="d-flex justify-content-center">
                  <ng-katex-paragraph [options]="{displayMode: true}" class="text-center"
                    [paragraph]="this.preguntasExamenPersonalizadoList[this.indice].pregunta"></ng-katex-paragraph>
                </div>
                <div *ngIf="this.preguntasExamenPersonalizadoList[this.indice].imagen" class="w-100 text-center">
                  <img [src]="this.preguntasExamenPersonalizadoList[this.indice].imagen[0].url" class="img-fluid">
                </div>
              </section>
              <!-- FIN DE PREGUNTAS EXAMEN PERSONALIZADO -->
              <!-- FIN DE PREGUNTAS -->

              <!-- RESPUESTAS EXAMEN SIMULACRO Y MINI SUMULACRO -->
              <section class="mt-4" *ngIf="this.preguntasList && this.preguntasList.length > 0" id="acordionRespuestas">

                <div *ngIf="!this.pregunta_especial_flag; else no_es_pregunta_especial">
                  <ng-container
                    *ngFor="let respuesta of this.preguntasList[indiceMaterias].preguntas[indice].respuesta; let i = index">
                    <button data-toggle="collapse" [attr.data-target]="'#r' + i"
                      [ngClass]="{'respuestaSeleccionada': (this.respuestas[this.indiceRespuestas][1] === i) && !this.examenCompletado, 'pregunta-correcta':(this.examenCompletado && respuesta.esCorrecta === 'true'), 'pregunta-incorrecta':(this.examenCompletado && this.respuestas[this.indiceRespuestas][1] === i && !this.respuestas[this.indiceRespuestas][0])}"
                      (click)="revisarRespuesta(respuesta.esCorrecta, i)"
                      class="btn w-100 text-center my-1 answers-options p-3">
                      <ng-katex-paragraph [paragraph]="respuesta.texto"></ng-katex-paragraph>
                    </button>
                    <div *ngIf="this.examenCompletado" class="collapse" data-parent="#acordionRespuestas"
                      [id]="'r' + i">
                      <div class="card card-body">
                        <ng-katex-paragraph *ngIf="respuesta.explicacion; else no_hay_explicacion"
                          [paragraph]="respuesta.explicacion"></ng-katex-paragraph>
                        <ng-template #no_hay_explicacion>
                          <span>No hay explicación disponible.</span>
                        </ng-template>
                      </div>
                    </div>
                  </ng-container>
                </div>
                <ng-template #no_es_pregunta_especial>
                  <div *ngIf="this.lectura_terminada_flag">
                    <ng-container
                      *ngFor="let respuesta of this.preguntasList[indiceMaterias].preguntaEspecial.preguntas[indice].respuesta; let i = index">
                      <button data-toggle="collapse" [attr.data-target]="'#r' + i"
                        [ngClass]="{'respuestaSeleccionada': (this.respuestas[this.indiceRespuestas][1] === i) && !this.examenCompletado, 'pregunta-correcta':(this.examenCompletado && respuesta.esCorrecta === 'true'), 'pregunta-incorrecta':(this.examenCompletado && this.respuestas[this.indiceRespuestas][1] === i && !this.respuestas[this.indiceRespuestas][0])}"
                        (click)="revisarRespuesta(respuesta.esCorrecta, i)"
                        class="btn btn-secondary w-100 text-center my-1 answers-options p-3">
                        <ng-katex-paragraph [paragraph]="respuesta.texto"></ng-katex-paragraph>
                      </button>
                      <div *ngIf="this.examenCompletado" class="collapse" data-parent="#acordionRespuestas"
                        [id]="'r' + i">
                        <div class="card card-body">
                          <ng-katex-paragraph *ngIf="respuesta.explicacion; else no_hay_explicacion"
                            [paragraph]="respuesta.explicacion"></ng-katex-paragraph>
                          <ng-template #no_hay_explicacion>
                            <span>No hay explicación disponible.</span>
                          </ng-template>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </ng-template>

              </section>
              <!-- FIN DE RESPUESTAS EXAMEN SIMULACRO Y MINI SIMULACRO -->
              <!-- RESPUESTAS EXAMEN PERSONALIZADO -->
              <section class="mt-4" *ngIf="this.preguntasExamenPersonalizadoList" id="acordionRespuestas">
                <ng-container
                  *ngFor="let respuesta of this.preguntasExamenPersonalizadoList[this.indice].respuesta; let i = index">
                  <button [disabled]="this.finalizado_flag" data-toggle="collapse" [attr.data-target]="'#r' + i"
                    [ngClass]="{'respuestaSeleccionada': (this.respuestas[this.indiceRespuestas][1] === i) && !this.examenCompletado, 'pregunta-correcta':(this.examenCompletado && respuesta.esCorrecta === 'true'), 'pregunta-incorrecta':(this.examenCompletado && this.respuestas[this.indiceRespuestas][1] === i && !this.respuestas[this.indiceRespuestas][0])}"
                    (click)="revisarRespuesta(respuesta.esCorrecta, i)"
                    class="btn w-100 text-center my-1 answers-options p-3">
                    <ng-katex-paragraph [paragraph]="respuesta.texto"></ng-katex-paragraph>
                  </button>
                  <div *ngIf="this.examenCompletado" class="collapse" data-parent="#acordionRespuestas" [id]="'r' + i">
                    <div class="card card-body">
                      <ng-katex-paragraph *ngIf="respuesta.explicacion; else no_hay_explicacion"
                        [paragraph]="respuesta.explicacion"></ng-katex-paragraph>
                      <ng-template #no_hay_explicacion>
                        <span>No hay explicación disponible.</span>
                      </ng-template>
                    </div>
                  </div>
                </ng-container>
              </section>
              <!-- FIN DE RESPUESTAS EXAMEN PERSONALIZADO -->
              <!-- FIN DE RESPUESTAS -->
            </div>
          </section>
          <!-- RESULTADOS FINALES -->
          <section *ngIf="finalizado_flag" class="d-block contenedor-aciertos text-center">
            <h5 class="w-100 text-center mb-3">Mapa de aciertos</h5>
            <div *ngFor="let respuesta of respuestas;let i = index" [ngClass]="{'pregunta-incorrecta': !respuesta[0]}"
              class="mapa-resultados m-1 text-center rounded"><a (click)="irAPregunta(i)"
                class="centrar-contenido pregunta-link h-100">{{i + 1}}</a></div>
          </section>
          <!-- FIN DE RESULTADOS FINALES -->
          <div class="buttons-bottom d-flex justify-content-between align-self-end">
            <button class="btn btn-secondary" (click)="retroceder()"
              *ngIf="!this.finalizado_flag && (indice > 0 || this.pregunta_especial_flag || this.indiceMaterias > 0)">Regresar</button>
            <button class="btn btn-primary ml-auto"
              *ngIf="!this.finalizado_flag && (this.indiceGeneral < (this.totalPreguntas - 1))"
              (click)="avanzar()">Siguiente</button>
            <button class="btn btn-primary ml-auto"
              *ngIf="!this.finalizado_flag && this.indiceGeneral >= (this.totalPreguntas - 1) && !this.examenCompletado"
              data-toggle="modal" data-target="#finalizarExamen">Finalizar</button>
            <button class="btn btn-primary ml-auto"
              *ngIf="!this.finalizado_flag && this.indiceGeneral >= (this.totalPreguntas - 1) && this.examenCompletado"
              (click)="toggleFinalizar()">Finalizar</button>
            <button class="btn btn-primary ml-auto" *ngIf="this.finalizado_flag" (click)="finalizar()">Terminar</button>
          </div>
        </div>
      </div>
    </div>
    <div class="barra-examen col-xl-3 p-3" [ngClass]="{'d-none': !this.finalizado_flag}">
      <h4 class="mb-3 text-left">Estadísticas</h4>
      <div class="border p-2 d-flex justify-content-between">
        <span>Calificación</span>
        <span class="estadisticas-color font-weight-bold">{{this.calificacionFinal}}</span>
      </div>
      <div class="border p-2 my-2 d-flex justify-content-between">
        <span>Aciertos</span>
        <span class="estadisticas-color font-weight-bold">{{this.aciertos}}</span>
      </div>
      <div class="border p-2 my-2 d-flex justify-content-between">
        <span>Errores</span>
        <span class="estadisticas-color font-weight-bold">{{this.errores}}</span>
      </div>
      <div class="border p-2 my-2 d-flex justify-content-between">
        <span>Tiempo</span>
        <span class="estadisticas-color font-weight-bold">{{this.tiempo}}</span>
      </div>
      <div class="border p-2 my-2 d-flex justify-content-between">
        <span>Tiempo por pregunta</span>
        <span class="estadisticas-color font-weight-bold">{{this.tiempoPorPregunta}}</span>
      </div>
      <div *ngIf="this.tipoExamen == 4" class="border p-2 my-2">
        <h5 class="w-100 text-center">{{this.mensaje1}}</h5>
        <hr>
        <h5 class="w-100 text-center">{{this.mensaje2}}</h5>
        <div class="w-100 text-center">
          <a class="mx-auto" (click)="irALanding()">Más información</a>
        </div>
      </div>
    </div>
  </div>
</div>


<!-- MODAL FINALIZAR EXAMEN -->
<div class="modal fade" id="finalizarExamen" tabindex="-1" aria-labelledby="finalizarExamenLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="finalizarExamenLabel">Estás a punto de terminar tu examen</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        ¿Desea terminar el examen?
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
        <button type="button" class="btn btn-primary" (click)="terminarExamen()" data-dismiss="modal">Finalizar</button>
      </div>
    </div>
  </div>
</div>
<!-- MODAL FINALIZAR EXAMEN -->
