import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Usuario } from '../models/usuario';
import { GLOBAL } from './global';
import * as moment from 'moment';
import { RUTAS } from './enums/rutas.enum';
import { Interesado } from '../models/Interesado';

@Injectable()
export class ServicioUsuario {
  public url: string;
  public urlCorreo: string;
  public identity;
  public token;
  public stats;

  constructor(
    public _http: HttpClient
  ) {
    this.url = GLOBAL.url + RUTAS.USUARIO;
    this.urlCorreo = GLOBAL.url + RUTAS.NODE_MAILER;
  }

  actualizarAvatar(email: string, file: File): Observable<any> {
    const fd = new FormData();
    fd.append('email', email);
    fd.append('avatar', file);

    return this._http.post(this.url + 'updateAvatar', fd);
  }

  registroUsuario(usuario: Usuario): Observable<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    let params = JSON.stringify(usuario);

    return this._http.post(this.url + 'registro-usuario/', params, { headers: headers });
  }

  verificarCorreo(correo: String): Observable<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.post(this.urlCorreo + 'enviar-correo/' + correo, { header: headers });
  }

  verificarCelular(correo: String): Observable<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this._http.get(this.url + 'verificar-telefono/' + correo, { headers: headers });
  }

  confirmarCorreo(datos): Observable<any> {
    let params = JSON.stringify(datos);
    let headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this._http.post(this.url + 'confirmar-correo', params, { headers: headers });
  }

  reiniciarContrasena(datos): Observable<any> {
    let params = JSON.stringify(datos);
    let headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this._http.post(this.url + 'reiniciar-contrasena', params, { headers: headers });
  }

  enviarCorreoCambioContrasena(correo): Observable<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this._http.post(this.urlCorreo + 'enviar-reset-contrasena/' + correo, { headers: headers });
  }

  correoBienvenida(correo): Observable<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this._http.post(this.urlCorreo + 'enviar-bienvenida/' + correo, { headers: headers });
  }

  correoCupon(correo): Observable<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this._http.post(this.urlCorreo + 'enviar-correo-cupon/' + correo, { headers: headers });
  }

  guardarInteresado(interesado: Interesado): Observable<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    let body = JSON.stringify(interesado);

    return this._http.post(this.url + 'guardar-interesado/', body, { headers: headers });
  }

  iniciarSesion(user, gettoken = null): Observable<any> {
    if (gettoken != null) {
      user.gettoken = gettoken;
    }

    let params = JSON.stringify(user);
    let headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this._http.post(this.url + 'login', params, { headers: headers });
  }

  iniciarSesionSocial(user, gettoken = null): Observable<any> {
    if (gettoken != null) {
      user.gettoken = gettoken;
    }

    let params = JSON.stringify(user);
    let headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this._http.post(this.url + 'login-social', params, { headers: headers });
  }

  actualizarInfo(user): Observable<any> {
    let params = JSON.stringify(user);
    let headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this._http.post(this.url + 'completar-datos', params, { headers: headers });
  }

  getIdentity() {
    try {
      let identity = JSON.parse(localStorage.getItem('identity'));
      if (identity != 'undefined') {
        this.identity = identity;
      } else {
        this.identity = null;
      }
    } catch (e) {
      this.identity = null;
    }

    return this.identity;
  }

  getToken() {
    let token = localStorage.getItem('token');

    if (token != 'undefined') {
      this.token = token;
    } else {
      this.token = null;
    }

    return this.token;
  }

  revisarPrivilegios() {
    let identity = JSON.parse(localStorage.getItem('identity'));
    let activado = 0;
    let curso = '';
    if (identity != 'undefined') {
      if (identity.cuenta[0].tipo == 'Gratis') {
        activado = 1;
      } else if (identity.cuenta[0].tipo == 'Medprep+') {
        activado = 2;
      } else if (identity.cuenta[0].tipo == 'Medprep Pro' && (+new Date(this.identity.cuenta[0].fecha_inicio)) <= +new Date()) {
        activado = 3;
      } else {
        activado = 1;
      }

      switch (identity.cuenta[0].tipo) {
        case 'Gratis':
          curso = 'a';
          return [activado, curso];
        case 'Medprep+':
          curso = 'b';
          return [activado, curso];
        case 'Medprep Pro':
          curso = 'c';
          return [activado, curso];
        default:
          curso = 'a';
          return [activado, curso];
      }
    } else {
      return [];
    }
  }

  revisarPlan(token): Observable<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', token);

    return this._http.get(this.url + 'check-plan', { headers: headers });
  }


  obtenerFechasPlan(token): Observable<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', token);

    return this._http.get(this.url + 'obtener-fechas', { headers: headers });
  }

  crearComentario(token, comentario): Observable<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', token);
    let params = JSON.stringify(comentario);

    return this._http.post(this.url + 'agregar-comentario', { comentario: params }, { headers: headers });
  }

  actualizarFoto(data: FormData): Observable<any> {
    return this._http.post(this.url + 'update-image', data);
  }


  actualizarPlan(token): Promise<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', token);
    let data = {
      numero: 0
    };
    return this._http.post(this.url + 'update-profile', { data }, { headers: headers }).toPromise();
  }

  comprobarSesion(): Observable<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    let token = localStorage.getItem('token');
    let data = {
      token: token
    }
    return this._http.post(this.url + 'revisar-sesion', data, { headers: headers });
  }

  obtenerTiempoEstudio(token): Observable<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', token);

    return this._http.get(this.url + 'obtener-tiempo-estudio', { headers: headers });
  }

  actualizarTiempoEstudio(token, tiempo): Observable<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', token);
    let data = {
      tiempo: tiempo
    }
    return this._http.post(this.url + 'actualizar-tiempo-estudio', data, { headers: headers });
  }

  borrarCuenta(token): Observable<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', token);

    return this._http.delete(this.url + 'borrar-cuenta', { headers: headers });
  }

  guardar(token, tiempo) {
    if (token != null) {
      this.actualizarTiempoEstudio(token, tiempo).subscribe(response => { });
    }
  }

}
