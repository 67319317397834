import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Usuario } from '../models/usuario';
import { GLOBAL } from './global';
import { RUTAS } from './enums/rutas.enum';

@Injectable()
export class ServicioClases {
    public url: string;

    constructor(
        public _http: HttpClient
    ) {
        this.url = GLOBAL.url + RUTAS.CLASES;
    }

    obtenerClases(fecha, materia, pagina): Observable<any> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');

        return this._http.get(this.url + 'obtener-clases-materia/' + fecha + '/' + materia + '/' + pagina, { headers: headers });
    }

    obtenerNumeroPaginasClases(fecha, materia): Observable<any> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');

        return this._http.get(this.url + 'obtener-paginacion/' + fecha + '/' + materia, { headers: headers });

    }

    subirClase(clase, token): Observable<any> {
        let claseData = JSON.stringify(clase);
        let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', token);;

        return this._http.post(this.url + 'subir-clase', claseData, { headers: headers });
    }

    borrarClase(id, token): Observable<any> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', token);;

        return this._http.delete(this.url + 'borrar-clase/' + id, { headers: headers });
    }

}
