import { ServicioExamen } from 'src/app/services/examen.service';
import { ServicioUsuario } from 'src/app/services/usuario.service';
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-pruebas',
  templateUrl: './pruebas.component.html',
  styleUrls: ['./pruebas.component.sass', './pruebas.component.css']
})
export class PruebasComponent implements AfterViewInit, OnInit {

  public token: string;

  constructor(private usuarioService: ServicioUsuario, private examenService: ServicioExamen) {
    this.token = this.usuarioService.getToken();
  }



  ngAfterViewInit(): void {

  }

  ngOnInit(): void {

  }

  irAPregunta(): void{
    console.log("test");

  }

}
