<footer class="mt-2 bg-dark">
    <div class="row p-4 k-footer w-95 m-auto">
        <div class="col-lg-3 col-12 my-auto  text-center">
            <a class="my-auto" href="https://www.medprep.com.mx/">
                <img class="k-logo-empresa img-fluid" src="../../../../assets/images/footer.png" alt="">
            </a>
            <small>&copy; Copyright 2023, Medprep Education</small>
        </div>
        <div class="col-lg-2 col-12 my-auto d-flex justify-content-center">
            <ul class="mt-4">
                <li>
                    <a class="text-decoration-none text-light" href="" [routerLink]="['/information/privacy-notice']">Aviso de
                        privacidad</a>
                </li>
                <!-- <li>Acerca de nosotros</li>
                <li>Historia</li>
                <li>Términos y condiciones</li> -->
                <li><a class="text-decoration-none text-light" href="" [routerLink]="['/information/contact']">Contacto</a></li>
                <li><a class="text-decoration-none text-light" href="" [routerLink]="['/information/faq']">FAQ</a></li>
                <li><a class="text-decoration-none text-light" href="" [routerLink]="['/information/contact']">Reportar un problema</a></li>
            </ul>

        </div>
        <div class="col-lg-7 col-12 p-3 k-redes-sociales">
            <p class="text-center">¡Síguenos en nuestras redes sociales!</p>
            <p class="text-center">Nos importa tu opinión y en caso de que tengas alguna duda con gusto te atenderemos
            </p>
            <a href="https://wa.me/message/MKMYS7XRPUCLA1" style="color: white;" class="kf py-0"><i class="fab fa-whatsapp"></i></a>
            <a href="https://www.facebook.com/MedPrepEdu/" class="kf py-0"><i class="fab fa-facebook"></i></a>
            <a href="https://twitter.com/MedPrepEdu" class="kf py-0"><i class="fab fa-twitter"></i></a>
            <a href="https://www.instagram.com/medprepedu/" class="kf py-0"><i class="fab fa-instagram"></i></a>
        </div>
    </div>
</footer>
