import { ServicioToastr } from './../../../services/toastr.service';
import { ServicioUsuario } from 'src/app/services/usuario.service';
import { Interesado } from './../../../models/Interesado';
import { Tema } from './../../../models/tema';
import { Materia } from './../../../models/materia';
import { ServicioTemas } from './../../../services/temas.service';
import { ServicioMaterias } from './../../../services/materias.service';
import { Component, OnInit, AfterContentChecked } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { TIPO_EXAMEN } from '../../../services/enums/tipo-examen.enum';
import { environment } from '../../../../environments/environment';


@Component({
  selector: 'app-recon-campaign',
  templateUrl: './recon-campaign.component.html',
  styleUrls: ['./recon-campaign.component.sass', './recon-campaign.component.css']
})
export class ReconCampaignComponent implements OnInit, AfterContentChecked {

  public materiasList: Materia[] = [];
  public temasList: Tema[] = [];
  public interesado: Interesado = new Interesado('', '', '', '');

  public customOptions: OwlOptions = {
    loop: true,
    margin: 32,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: true,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 3
      },
      1350: {
        items: 5
      }
    },
    nav: false
  };

  public vw: number = 0;

  public apiLoaded: boolean = false;
  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private _servicioMaterias: ServicioMaterias,
    private _servicioTemas: ServicioTemas,
    private _servicioUsuario: ServicioUsuario,
    private _servicioToastr: ServicioToastr
  ) {


  }

  ngAfterContentChecked(): void {
    this.scrollInto();
  }

  guardarDatosInteresado(): void {
    this._servicioUsuario.guardarInteresado(this.interesado).subscribe({
      next: (data) => {
        if (data) {
          if (environment.production) {
            fbq('trackCustom', 'Apartar curso');
          }
          this._servicioToastr.showSuccess('¡Tu curso esta apartado!');
        }
      }, error: (error) => {
        this._servicioToastr.showError('Hubo un error, recargar la página e intenta nuevamente');
      }
    });
  }

  ngOnInit(): void {
    if (environment.production) {
      fbq('trackCustom', 'Ver landing');
    }
    this.vw = window.innerWidth - 30;
    this.obtenerMaterias();
  }

  obtenerMaterias(): void {
    this._servicioMaterias.obtenerMaterias().subscribe({
      next: (data) => {
        this.materiasList = data.materias;
      }, error: (error) => { }
    });
  }

  generarExamenPredeterminado(): void {
    localStorage.setItem("tipoExamen", TIPO_EXAMEN.PREDETERMINADO.toString());
    this.iniciarPrueba();
    this._router.navigate(["/exam"]);
  }

  obtenerTemas(materiaId: string): void {
    this.temasList = [];
    this._servicioTemas.obtenerTemasPorId(materiaId).subscribe({
      next: (data) => {
        this.temasList = data.temas;
      }, error: (error) => { }
    });
  }

  //Obtiene la url de la ruta
  scrollInto() {
    this._route.params.subscribe(params => {
      if (params['id']) {
        let whatIs = document.getElementById("2");
        whatIs.scrollIntoView({ behavior: "smooth" });
      }
    });
  }

  getVideoId(url) {
    var video: string, results: string;

    if (url === null) {
      return '';
    }
    results = url.match('[\\?&]v=([^&#]*)');
    video = (results === null) ? url : results[1];

    return video;
  }

  comprar(): void {
    if (environment.production) {
      fbq('trackCustom', 'Interesado en comprar');
    }
    this._router.navigate(["information/prices"]);
  }

  obtenerGuia(): void {
    if (environment.production) {
      fbq('trackCustom', 'Descargar guía');
    }
  }

  iniciarPrueba(): void {
    if (environment.production) {
      fbq('track', 'StartTrial');
    }
  }

}
