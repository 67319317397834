<div class="container-info-contact-img"></div>
<div class="container-fluid my-5 ">

    <div class="w-100 text-center my-5">
        <h1 class="font-title-medprep">¡Nos <span class="texto-especial">encanta</span> escucharte!</h1>
        <span class="d-block font-text-medprep">Aquí encontrarás nuestros datos de contacto</span>
    </div>
    <div class="row">
        <div class="col-md-4 mt-5">
            <div class="form-image p-5 mt-5 text-center mx-auto">
                <h1 class="font-title-medprep title-form mb-5">Ofrece <span class="texto-especial">MedPrep</span> a tus estudiantes
                </h1>

                <div class="font-text-medprep">
                    <span class="d-block">¿Tienes un curso pre-médico y quieres ofrecer acceso a MedPrep a tus
                        estudiantes?</span>
                    <span class="d-block">Nos encantaría hacer negocios contigo, contáctanos en nuestro correo, tenemos
                        precios especiales para ti:</span>
                </div>
                <div class="font-text-medprep mt-4">
                    <span class="d-block font-weight-bold">negocios@medprep.com.mx</span>
                </div>

            </div>
        </div>
        <div class="col-md-4 mt-5">
            <div class="form-image p-5 mt-5 text-center mx-auto">
                <h1 class="font-title-medprep title-form mb-5">¿<span class="texto-especial">Dudas</span> acerca del curso?</h1>

                <div class="font-text-medprep">
                    <span class="d-block">¿No te preocupes! Hemos hecho una lista de preguntas frecuentes que puedes
                        encontrar <a href="" [routerLink]="['/information/faq']">aquí</a>.</span>
                    <span class="d-block">Si no encuentras la pregunta que tienes nos encantaría que nos mandaras un
                        mensaje a nuestro WhatsApp:</span>
                </div>
                <div class="font-text-medprep mt-4">
                    <span class="d-block font-weight-bold">+52 8714066276</span>
                </div>

                <div class="w-100 text-center mt-4">
                    <a class="btn btn-medprep" href="https://walink.co/a02ae2">¡Mándanos un mensaje!</a>
                </div>
            </div>
        </div>
        <div class="col-md-4 my-5">
            <div class="form-image p-5 mt-5 text-center mx-auto">
                <h1 class="font-title-medprep title-form mb-5">¿<span class="texto-especial">Problemas</span> con el sitio?
                </h1>

                <div class="font-text-medprep mb-5">
                    <span class="d-block">¡Muchas gracias por tu paciencia!</span>
                    <span class="d-block">Por favor, mandanos un mensaje a nuestro correo de soporte:</span>
                </div>
                <div class="font-text-medprep mt-5 h-100">
                    <span class="d-block font-weight-bold my-auto">soporte@medprep.com.mx</span>
                </div>
            </div>
        </div>
    </div>
</div>