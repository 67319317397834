import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GLOBAL } from './global';
import { RUTAS } from './enums/rutas.enum';

@Injectable()
export class ServicioGrupoTemas {
  public url: string;

  constructor(
    public _http: HttpClient
  ) {
    this.url = GLOBAL.url + RUTAS.GRUPO_TEMAS;
  }

  // Retorna los grupos existentes
  obtenerGrupoTemas(materia: string): Observable<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this._http.get(this.url + 'obtener-grupos/' + materia, { headers: headers });
  }

  // Retorna los temas de un grupo
  obtenerTemasGrupo(grupoId: string): Observable<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this._http.get(this.url + 'obtener-temas-de-grupo/' + grupoId, { headers: headers });
  }

  //Agrega los temas a un grupo
  agregarTemasAGrupo(temas: string[], grupoId: string, token: string): Observable<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', token);;
    let params = {
      temas,
      grupoId
    };

    return this._http.post(this.url + 'agregar-tema-a-grupo', params, { headers: headers });
  }

  // Por materia retorna sus grupos y respectivos temas
  obtenerGruposPorMateria(materiaId: string): Observable<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this._http.get(this.url + 'obtener-temas-agrupados/' + materiaId, { headers: headers });
  }
}
