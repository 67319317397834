import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.sass', './faq.component.css']
})
export class FAQComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  collapseCheck(collapse: number) {
    if (collapse == 1) {
      document.getElementById('collapse1').classList.remove('d-none');
      document.getElementById('collapse2').classList.add('d-none');
      document.getElementById('collapse3').classList.add('d-none');

      document.getElementById('botonPrimero').classList.add('btn-nav-faq-selected');
      document.getElementById('boton2').classList.remove('btn-nav-faq-selected');
      document.getElementById('boton3').classList.remove('btn-nav-faq-selected');
    } else if (collapse == 2) {
      document.getElementById('collapse1').classList.add('d-none');
      document.getElementById('collapse2').classList.remove('d-none');
      document.getElementById('collapse3').classList.add('d-none');
      
      document.getElementById('botonPrimero').classList.remove('btn-nav-faq-selected');
      document.getElementById('boton2').classList.add('btn-nav-faq-selected');
      document.getElementById('boton3').classList.remove('btn-nav-faq-selected');
    } else if (collapse == 3) {
      document.getElementById('collapse1').classList.add('d-none');
      document.getElementById('collapse2').classList.add('d-none');
      document.getElementById('collapse3').classList.remove('d-none');

      
      document.getElementById('botonPrimero').classList.remove('btn-nav-faq-selected');
      document.getElementById('boton2').classList.remove('btn-nav-faq-selected');
      document.getElementById('boton3').classList.add('btn-nav-faq-selected');
    }
  }
}
