<div class="">
    <!--SECCION 1-->
    <div class="container">

        <div class="modo-escritorio">
            <div class="main-image-uno"></div>
            <div class="row">
                <div class="col-md-5 text-center pantalla-uno-fix centrar-contenido">
                    <div class="text-left w-100 mx-auto my-auto ">
                        <h1 class="m-0 bold-title main-font-title">Pasa tu examen de medicina</h1>

                        <div class="my-2 main-font-body">
                            <p>Medprep es el único curso premédico que necesitas. Completo, personalizado y con los temas que vendrán en tu exámen de admisión. </p>
                        </div>
                        <div>
                            <div class="input-group mb-3 ">
                                <div class="input-group-prepend">
                                    <span class="input-group-text mod-input-email" id="basic-addon1"></span>
                                </div>
                                <input type="text" name="correo" #correo="ngModel" [(ngModel)]="data.correo" class="shadow form-control input-email" placeholder="Email aquí" aria-label="email" aria-describedby="basic-addon1">
                            </div>
                        </div>
                        <div class="my-2">
                            <div class="row">
                                <div class="col-md-6">
                                    <button class="boton-main-page px-4 main-font-button shadow w-100" (click)="sendData()">Pruébalo gratis</button>
                                </div>
                                <div class="col-md-6">
                                    <button class="boton-comprar px-4 main-font-button shadow w-100" [routerLink]="['/information/prices']" routerLinkActive="router-link-active">Comprar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-7 centrar-contenido">
                    <button (click)="videoMain()" type="button" class="btn" data-toggle="modal" data-target="#modalMain">
                        <img src="../../../../assets/images/main-page/main-image.png" class="img-fluid" alt="">
                      </button>
                </div>
            </div>


        </div>


        <div class="modo-celular mt-3 mb-5">
            <div class="text-center">

                <button (click)="videoMain()" type="button" class="btn text-center" data-toggle="modal" data-target="#modalMain">
                    <img src="../../../../assets/images/main-page/main-image.png" class="img-fluid" alt="">
                  </button>

                <div class="text-left w-100 mx-auto mt-3">
                    <h1 class="m-0 bold-title main-font-title">Pasa tu examen de medicina</h1>

                    <div class="my-2 main-font-body">
                        <p>El único curso premédico que necesitas. </p>
                    </div>
                    <div>
                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text mod-input-email" id="basic-addon1"></span>
                            </div>
                            <input type="text" name="correo" #correo="ngModel" [(ngModel)]="data.correo" class="shadow form-control input-email" placeholder="Email aquí" aria-label="email" aria-describedby="basic-addon1">
                        </div>
                    </div>
                    <div class="my-2">
                        <button class="boton-main-page px-4 main-font-button shadow w-100" (click)="sendData()">Pruébalo gratis</button>
                        <button class="boton-comprar px-4 main-font-button shadow w-100 mt-2" [routerLink]="['/information/prices']" routerLinkActive="router-link-active">Comprar</button>
                    </div>
                </div>
            </div>
        </div>



    </div>

    <div class="modo-escritorio mt-5 w-100 centrar-contenido text-center p-4" style="background-color: rgba(242, 227, 114, 0.41);">
        <!-- <img src="../../../../assets/images/main-page/guias.png" class="mx-auto img-fluid" alt=""> -->
        <img src="../../../../assets/images/main-page/CENEVAL.png" class="img-fluid mx-5" alt="">
        <img src="../../../../assets/images/main-page/EXANI-11.png" class="img-fluid mx-5" alt="">
        <img src="../../../../assets/images/main-page/college.png" class="img-fluid mx-5" alt="">
        <img src="../../../../assets/images/main-page/EXBACH.png" class="img-fluid mx-5" alt="">
    </div>

    <div class="container-fluid mb-5" style="background-color: rgba(242, 227, 114, 0.41);">
        <div class="modo-celular mt-5 w-100 centrar-contenido text-center p-4" style="height: 100% !important;">
            <div class="row">
                <div class="col-md-6 centrar-contenido">
                    <img src="../../../../assets/images/main-page/CENEVAL.png" class="img-fluid mx-5 my-3" style="width: 100px !important;" alt="">
                    <img src="../../../../assets/images/main-page/EXANI-11.png" class="img-fluid mx-5 my-3" style="width: 100px !important;" alt="">

                </div>
                <div class="col-md-6 centrar-contenido">
                    <img src="../../../../assets/images/main-page/college.png" class="img-fluid mx-5 my-3" style="width: 100px !important;" alt="">
                    <img src="../../../../assets/images/main-page/EXBACH.png" class="img-fluid mx-5 my-3" style="width: 100px !important;" alt="">

                </div>
            </div>
        </div>
    </div>


    <!--SECCION 1-->

    <!-- MODAL -->
    <div class="modal fade" id="modalMain" tabindex="-1" aria-labelledby="modalMainLabel" aria-hidden="true">
        <div class="modal-dialog modal-xl modal-dialog-centered">
            <div class="modal-content p-2 text-center">
                <div class="embed-responsive embed-responsive-16by9 mx-auto">
                    <video class="video-main-page" id="videoPlayer" #videoPlayerMain>
                    <source src="https://res.cloudinary.com/dq17wf3fb/video/upload/v1645554801/Medprep%20Videos/videoMainPage_aeuedr.mp4" type="video/mp4" />
                    Browser not supported
                </video>
                </div>
            </div>
        </div>
    </div>
    <!-- MODAL -->



    <!-- CARUSEL -->
    <div class="mt-5 pantalla-carrusel">
        <div class="w-100 text-center">
            <h5 class="" style="color: #00AAFF; font-size: 14.4px;">Opiniones de nuestros alumnos</h5>
        </div>


        <div class="carusel-background-uno modo-escritorio">
            <owl-carousel-o [options]="customOptions">
                <ng-template carouselSlide class="centrar-resena">
                    <div class="p-3">
                        <div class=" card px-4 shadow" style="width: 18rem; border-radius: 10px;  min-height: 390px !important;">
                            <div class="card-body text-center pt-5">
                                <img src="../../../../assets/images/main-page/reseñas/Samantha.jpeg" class="resena-imagen mx-auto" alt="">
                                <h5 style="margin-top: 6px;" class="fuente-resena-1 mb-0">Samantha Reyes</h5>
                                <h6 class="mb-2  fuente-resena-2">Medicina UAC</h6>
                                <p class="pt-3 fuente-resena-3">La verdad estuvo increíble, aprendí muy bien y los profes fueron muy profesionales y agradables. También la página en línea es de gran ayuda para repasar, estudiar y conocer que tan bien o mal vamos.</p>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="p-3">
                        <div class="card px-4 shadow" style="width: 18rem; border-radius: 10px;  min-height: 390px !important;">
                            <div class="card-body text-center pt-5">
                                <img src="../../../../assets/images/main-page/reseñas/Ana Rodriguez.jpeg" class="resena-imagen mx-auto" alt="">
                                <h5 style="margin-top: 6px;" class="fuente-resena-1 mb-0">Ana Rodríguez</h5>
                                <h6 class="mb-2  fuente-resena-2">Medicina UAC</h6>
                                <p class="pt-3 fuente-resena-3">Ha sido de las mejores cosas que he vivido. Los profes son muy buenos, "capturan" tu atención y caen súper bien. La información es breve y concisa, justo lo necesario.</p>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="p-3">
                        <div class="card px-4 shadow" style="width: 18rem; border-radius: 10px;  min-height: 390px !important;">
                            <div class="card-body text-center pt-5">
                                <img src="../../../../assets/images/main-page/reseñas/Evelyn.jpeg" class="resena-imagen mx-auto" alt="">
                                <h5 style="margin-top: 6px;" class="fuente-resena-1 mb-0">Evelyn Treviño</h5>
                                <h6 class="mb-2  fuente-resena-2">Medicina UAC</h6>
                                <p class="pt-3 fuente-resena-3">Es un curso muy completo, innovador e intensivo. Su plataforma, si la sabes aprovechar es una joyita.</p>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="p-3">
                        <div class="card px-4 shadow" style="width: 18rem; border-radius: 10px;  min-height: 390px !important;">
                            <div class="card-body text-center pt-5">
                                <img src="../../../../assets/images/main-page/reseñas/Daniela.jpeg" class="resena-imagen mx-auto" alt="">
                                <h5 style="margin-top: 6px;" class="fuente-resena-1 mb-0">Daniela Mascorro</h5>
                                <h6 class="mb-2  fuente-resena-2">Medicina UAC</h6>
                                <p class="pt-3 fuente-resena-3">Un curso muy completo, donde se vieron temas muy importantes y se trató de evitar información de relleno. Dan mucha atención a los alumnos. Los profesores son los mejores.</p>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="p-3">
                        <div class="card px-4 shadow" style="width: 18rem; border-radius: 10px;  min-height: 390px !important;">
                            <div class="card-body text-center pt-5">
                                <img src="../../../../assets/images/main-page/reseñas/Angie.jpeg" class="resena-imagen mx-auto" alt="">
                                <h5 style="margin-top: 6px;" class="fuente-resena-1 mb-0">Angie Marentes</h5>
                                <h6 class="mb-2  fuente-resena-2">Medicina UAC</h6>
                                <p class="pt-3 fuente-resena-3">Fue una muy buena elección. La relación que se podía tener con los maestros era muy buena.</p>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="p-3">
                        <div class="card px-4 shadow" style="width: 18rem; border-radius: 10px;  min-height: 390px !important;">
                            <div class="card-body text-center pt-5">
                                <img src="../../../../assets/images/main-page/reseñas/Andres.jpeg" class="resena-imagen mx-auto" alt="">
                                <h5 style="margin-top: 6px;" class="fuente-resena-1 mb-0">Andres González</h5>
                                <h6 class="mb-2  fuente-resena-2">Medicina UAC</h6>
                                <p class="pt-3 fuente-resena-3">Repasar de último momento con las flashcards si tira un parote.</p>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="p-3">
                        <div class="card px-4 shadow" style="width: 18rem; border-radius: 10px;  min-height: 390px !important;">
                            <div class="card-body text-center pt-5">
                                <img src="../../../../assets/images/main-page/reseñas/Francisco.jpeg" class="resena-imagen mx-auto" alt="">
                                <h5 style="margin-top: 6px;" class="fuente-resena-1 mb-0">Francisco Herrera</h5>
                                <h6 class="mb-2  fuente-resena-2">Medicina UAC</h6>
                                <p class="pt-3 fuente-resena-3">Los profesores son muy buena onda. Al principio no sabía muchas cosas, pero repasando los resúmenes te das muy buena idea de todo y los profes complementan muy bien.</p>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="p-3">
                        <div class="card px-4 shadow" style="width: 18rem; border-radius: 10px;  min-height: 390px !important;">
                            <div class="card-body text-center pt-5">
                                <img src="../../../../assets/images/main-page/reseñas/Santiago.jpeg" class="resena-imagen mx-auto" alt="">
                                <h5 style="margin-top: 6px;" class="fuente-resena-1 mb-0">Santiago Martínez</h5>
                                <h6 class="mb-2  fuente-resena-2">Medicina UAC</h6>
                                <p class="pt-3 fuente-resena-3">Yo creo que presentar exámenes simulacro es lo que más me ayudó a acostumbrarme al tipo de preguntas del exámen.</p>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="p-3">
                        <div class="card px-4 shadow" style="width: 18rem; border-radius: 10px; min-height: 390px !important;">
                            <div class="card-body text-center pt-5">
                                <img src="../../../../assets/images/main-page/reseñas/Camila.jpeg" class="resena-imagen mx-auto" alt="">
                                <h5 style="margin-top: 6px;" class="fuente-resena-1 mb-0">Camila Betancourt</h5>
                                <h6 class="mb-2  fuente-resena-2">Medicina UAC</h6>
                                <p class="pt-3 fuente-resena-3">Me sentí muy segura en el examen de admisión porque sentí que era como presentar otro examen simulacro. Las clases con los profes también estaban muy cool.</p>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>


        <div class="modo-celular">
            <div id="carouselOpiniones" class="carousel slide" style="background-color: rgba(0, 170, 255, 0.2);" data-ride="carousel">
                <ol class="carousel-indicators">
                    <li data-target="#carouselOpiniones" data-slide-to="0" class="active"></li>
                    <li data-target="#carouselOpiniones" data-slide-to="1"></li>
                    <li data-target="#carouselOpiniones" data-slide-to="2"></li>
                    <li data-target="#carouselOpiniones" data-slide-to="3"></li>
                    <li data-target="#carouselOpiniones" data-slide-to="4"></li>
                    <li data-target="#carouselOpiniones" data-slide-to="5"></li>
                    <li data-target="#carouselOpiniones" data-slide-to="6"></li>
                    <li data-target="#carouselOpiniones" data-slide-to="7"></li>
                    <li data-target="#carouselOpiniones" data-slide-to="8"></li>
                    <li data-target="#carouselOpiniones" data-slide-to="9"></li>
                </ol>
                <div class="carousel-inner">
                    <div class="carousel-item active my-5">
                        <div class="card px-4 shadow mx-auto" style="width: 18rem; border-radius: 10px; min-height: 390px;">
                            <div class="card-body text-center pt-5">
                                <img src="../../../../assets/images/main-page/reseñas/Samantha.jpeg" class="resena-imagen mx-auto" alt="">
                                <h5 style="margin-top: 6px;" class="fuente-resena-1 mb-0">Samantha Reyes</h5>
                                <h6 class="mb-2  fuente-resena-2">Medicina UAC</h6>
                                <p class="pt-3 fuente-resena-3">La verdad estuvo increíble, aprendí muy bien y los profes fueron muy profesionales y agradables. También la página en línea es de gran ayuda para repasar, estudiar y conocer que tan bien o mal vamos..</p>
                            </div>
                        </div>
                    </div>
                    <div class="carousel-item">
                        <div class="card px-4 shadow mx-auto my-5" style="width: 18rem; border-radius: 10px; min-height: 390px;">
                            <div class="card-body text-center pt-5">
                                <img src="../../../../assets/images/main-page/reseñas/Ana Rodriguez.jpeg" class="resena-imagen mx-auto" alt="">
                                <h5 style="margin-top: 6px;" class="fuente-resena-1 mb-0">Ana Rodríguez</h5>
                                <h6 class="mb-2  fuente-resena-2">Medicina UAC</h6>
                                <p class="pt-3 fuente-resena-3">Ha sido de las mejores cosas que he vivido. Los profes son muy buenos, "capturan" tu atención y caen súper bien. La información es breve y concisa, justo lo necesario.</p>
                            </div>
                        </div>
                    </div>
                    <div class="carousel-item">
                        <div class="card px-4 shadow mx-auto my-5" style="width: 18rem; border-radius: 10px; min-height: 390px;">
                            <div class="card-body text-center pt-5">
                                <img src="../../../../assets/images/main-page/reseñas/Evelyn.jpeg" class="resena-imagen mx-auto" alt="">
                                <h5 style="margin-top: 6px;" class="fuente-resena-1 mb-0">Evelyn Treviño</h5>
                                <h6 class="mb-2  fuente-resena-2">Medicina UAC</h6>
                                <p class="pt-3 fuente-resena-3">Es un curso muy completo, innovador e intensivo. Su plataforma, si la sabes aprovechar es una joyita.</p>
                            </div>
                        </div>
                    </div>
                    <div class="carousel-item">
                        <div class="card px-4 shadow mx-auto my-5" style="width: 18rem; border-radius: 10px;min-height: 390px;">
                            <div class="card-body text-center pt-5">
                                <img src="../../../../assets/images/main-page/reseñas/Sayra.jpeg" class="resena-imagen mx-auto" alt="">
                                <h5 style="margin-top: 6px;" class="fuente-resena-1 mb-0">Sayra Flores</h5>
                                <h6 class="mb-2  fuente-resena-2">Medicina UAC</h6>
                                <p class="pt-3 fuente-resena-3">Me pareció un excelente curso porque era muy dinámico y los profes explicaban muy bien.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="carousel-item">
                        <div class="card px-4 shadow mx-auto my-5" style="width: 18rem; border-radius: 10px; min-height: 390px;">
                            <div class="card-body text-center pt-5">
                                <img src="../../../../assets/images/main-page/reseñas/Daniela.jpeg" class="resena-imagen mx-auto" alt="">
                                <h5 style="margin-top: 6px;" class="fuente-resena-1 mb-0">Daniela Mascorro</h5>
                                <h6 class="mb-2  fuente-resena-2">Medicina UAC</h6>
                                <p class="pt-3 fuente-resena-3">Un curso muy completo, donde se vieron temas muy importantes y se trató de evitar información de relleno. Dan mucha atención a los alumnos. Los profesores son los mejores.</p>
                            </div>
                        </div>
                    </div>
                    <div class="carousel-item">
                        <div class="card px-4 shadow mx-auto my-5" style="width: 18rem; border-radius: 10px; min-height: 390px;">
                            <div class="card-body text-center pt-5">
                                <img src="../../../../assets/images/main-page/reseñas/Angie.jpeg" class="resena-imagen mx-auto" alt="">
                                <h5 style="margin-top: 6px;" class="fuente-resena-1 mb-0">Angie Marentes</h5>
                                <h6 class="mb-2  fuente-resena-2">Medicina UAC</h6>
                                <p class="pt-3 fuente-resena-3">Fue una muy buena elección. La relación que se podía tener con los maestros era muy buena.</p>
                            </div>
                        </div>
                    </div>
                    <div class="carousel-item">
                        <div class="card px-4 shadow mx-auto my-5" style="width: 18rem; border-radius: 10px; min-height: 390px;">
                            <div class="card-body text-center pt-5">
                                <img src="../../../../assets/images/main-page/reseñas/Andres.jpeg" class="resena-imagen mx-auto" alt="">
                                <h5 style="margin-top: 6px;" class="fuente-resena-1 mb-0">Andres González</h5>
                                <h6 class="mb-2  fuente-resena-2">Medicina UAC</h6>
                                <p class="pt-3 fuente-resena-3">Repasar de último momento con las flashcards si tira un parote.</p>
                            </div>
                        </div>
                    </div>
                    <div class="carousel-item">
                        <div class="card px-4 shadow mx-auto my-5" style="width: 18rem; border-radius: 10px; min-height: 390px;">
                            <div class="card-body text-center pt-5">
                                <img src="../../../../assets/images/main-page/reseñas/Francisco.jpeg" class="resena-imagen mx-auto" alt="">
                                <h5 style="margin-top: 6px;" class="fuente-resena-1 mb-0">Francisco Herrera</h5>
                                <h6 class="mb-2  fuente-resena-2">Medicina UAC</h6>
                                <p class="pt-3 fuente-resena-3">Los profesores son muy buena onda. Al principio no sabía muchas cosas, pero repasando los resúmenes te das muy buena idea de todo y los profes complementan muy bien.</p>
                            </div>
                        </div>
                    </div>
                    <div class="carousel-item">
                        <div class="card px-4 shadow mx-auto my-5" style="width: 18rem; border-radius: 10px; min-height: 390px;">
                            <div class="card-body text-center pt-5">
                                <img src="../../../../assets/images/main-page/reseñas/Santiago.jpeg" class="resena-imagen mx-auto" alt="">
                                <h5 style="margin-top: 6px;" class="fuente-resena-1 mb-0">Santiago Martínez</h5>
                                <h6 class="mb-2  fuente-resena-2">Medicina UAC</h6>
                                <p class="pt-3 fuente-resena-3">Yo creo que presentar exámenes simulacro es lo que más me ayudó a acostumbrarme al tipo de preguntas del exámen. Además, te acostumbras al formato, tiempo y estructura del examen real para llegar con menos nervios.</p>
                            </div>
                        </div>
                    </div>
                    <div class="carousel-item">
                        <div class="card px-4 shadow mx-auto my-5" style="width: 18rem; border-radius: 10px; min-height: 390px;">
                            <div class="card-body text-center pt-5">
                                <img src="../../../../assets/images/main-page/reseñas/Camila.jpeg" class="resena-imagen mx-auto" alt="">
                                <h5 style="margin-top: 6px;" class="fuente-resena-1 mb-0">Camila Betancourt</h5>
                                <h6 class="mb-2  fuente-resena-2">Medicina UAC</h6>
                                <p class="pt-3 fuente-resena-3">Me sentí muy segura en el examen de admisión porque sentí que era como presentar otro examen simulacro. Las clases con los profes también estaban muy cool.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <a class="carousel-control-prev" href="#carouselOpiniones" role="button" data-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="sr-only">Previous</span>
                </a>
                <a class="carousel-control-next" href="#carouselOpiniones" role="button" data-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="sr-only">Next</span>
                </a>
            </div>
        </div>

    </div>


    <!-- CARUSEL -->




    <!--SECCION 2-->
    <div class="container pantalla-dos">

        <div class="modo-escritorio">
            <div class="main-image-dos"></div>
            <div class="row">
                <div class="col-md-6 text-center pantalla-uno centrar-contenido">
                    <div class="text-left w-100 mx-auto my-auto ">
                        <h1 class="bold-title main-font-title">No lo decimos nosotros</h1>
                        <div class="my-2 main-font-body">
                            <p>Conoce a nuestros estudiantes y deja que ellos te hablen de su experiencia en MedPrep.</p>
                        </div>

                        <div class="my-2">
                            <button class="shadow boton-main-page px-4 main-font-button" [routerLink]="['/auth/registry']" routerLinkActive="router-link-active">Pruébalo gratis</button>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 centrar-contenido">
                    <button class="btn" (click)="videoRes()" data-toggle="modal" data-target="#modalRes">
                        <img src="../../../../assets/images/main-page/celPlay.png" class="img-fluid" alt="">
                    </button>
                </div>
            </div>
        </div>


        <div class="modo-celular mt-5">
            <button class="btn" (click)="videoRes()" data-toggle="modal" data-target="#modalRes">
                <img src="../../../../assets/images/main-page/celPlay.png" class="img-fluid" alt="">
            </button>

            <div class="text-left w-100 mx-auto mt-5 ">
                <h1 class="bold-title main-font-title">No lo decimos nosotros</h1>
                <div class="my-2 main-font-body">
                    <p>Conoce a nuestros estudiantes y deja que ellos te hablen de su experiencia en MedPrep.</p>
                </div>

                <div class="my-2 text-center">
                    <button class="shadow boton-main-page px-4 main-font-button" [routerLink]="['/auth/registry']" routerLinkActive="router-link-active">Pruébalo gratis</button>
                </div>
            </div>
        </div>

    </div>


    <!-- MODAL -->
    <div class="modal fade" id="modalRes" tabindex="-1" aria-labelledby="modalResLabel" aria-hidden="true">
        <div class="modal-dialog modal-xl modal-dialog-centered">
            <div class="modal-content p-2 text-center mx-auto" style="width: auto !important;">
                <div class="embed-responsive embed-responsive-16by9 mx-auto" style="width: 300px !important; height: 550px !important;">
                    <video class="" id="videoPlayer" #videoPlayerRes>
                        <source src="https://res.cloudinary.com/dq17wf3fb/video/upload/v1645554793/Medprep%20Videos/videoHome_o6bths.mp4" type="video/mp4" />
                        Browser not supported
                    </video>
                </div>
                <div class="modal-footer p-0">
                    <button class="btn btn-medprep" data-dismiss="modal">Cerrar</button>
                </div>
            </div>
        </div>
    </div>
    <!-- MODAL -->

    <!--SECCION 2-->


    <!-- SECCION 3 -->
    <div class="pantalla-tres">
        <div class="modo-escritorio">
            <div class="main-image-tres"></div>
            <div class="w-100">
                <div class="mx-auto text-center" style="max-width: 90%;">
                    <h1 class="m-0 bold-title main-font-title">El pre-médico hecho a tu medida</h1>
                    <div class="my-2 main-font-body text-center mx-auto" style="max-width: 70%;">
                        <p>La mayoria de los aceptados en medicina tomaron algún curso pre-médico, estudia con nosotros y ten acceso a las mejores herramientas, resúmenes y exámenes.</p>
                    </div>
                </div>

                <div class="mt-5 mx-0">
                    <div class="carusel-background-uno">
                        <owl-carousel-o [options]="customOptions">
                            <ng-template carouselSlide class="centrar-resena">
                                <div class="p-3">
                                    <div class="card shadow" style="width: 18rem; border-radius: 10px;">
                                        <div class="centrar-contenido tarjeta-pantalla-3">
                                            <img data-toggle="modal" data-target="#modal1" src="../../../../assets/images/main-page/libros.png" class="card-img-top p-3 cursor w-75" alt="...">
                                        </div>
                                        <div class="card-body">
                                            <p class="card-text">Con nosotros no necesitaras apuntes...</p>
                                            <a href="#" class="card-link" data-toggle="modal" data-target="#modal1">Ver más ></a>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                            <ng-template carouselSlide>
                                <div class="p-3">
                                    <div class="card shadow" style="width: 18rem; border-radius: 10px;">
                                        <div class="centrar-contenido tarjeta-pantalla-3">
                                            <img src="../../../../assets/images/main-page/medalla.png" data-toggle="modal" data-target="#modal2" class="card-img-top p-3 cursor w-75" alt="...">
                                        </div>
                                        <div class="card-body">
                                            <p class="card-text">Obtén una calificacion más alta que los demás...</p>
                                            <a href="#" class="card-link" data-toggle="modal" data-target="#modal2">Ver más ></a>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                            <ng-template carouselSlide>
                                <div class="p-3">
                                    <div class="card shadow" style="width: 18rem; border-radius: 10px;">
                                        <div class="centrar-contenido tarjeta-pantalla-3">
                                            <img src="../../../../assets/images/main-page/examenReloj.png" data-toggle="modal" data-target="#modal3" class="card-img-top p-3 cursor w-75" alt="...">
                                        </div>
                                        <div class="card-body">
                                            <p class="card-text">Estudia lo necesario y sin perder tiempo...</p>
                                            <a href="#" class="card-link" data-toggle="modal" data-target="#modal3">Ver más ></a>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                            <ng-template carouselSlide>
                                <div class="p-3">
                                    <div class="card shadow" style="width: 18rem; border-radius: 10px;">
                                        <div class="centrar-contenido tarjeta-pantalla-3">
                                            <img src="../../../../assets/images/main-page/imagenCerebro.png" data-toggle="modal" data-target="#modal4" class="card-img-top p-3 cursor w-75" alt="...">
                                        </div>
                                        <div class="card-body">
                                            <p class="card-text">Conóce tus áreas fuertes y refuerza las débiles...</p>
                                            <a href="#" class="card-link" data-toggle="modal" data-target="#modal4">Ver más ></a>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                            <ng-template carouselSlide>
                                <div class="p-3">
                                    <div class="card shadow" style="width: 18rem; border-radius: 10px;">
                                        <div class="centrar-contenido tarjeta-pantalla-3">
                                            <img src="../../../../assets/images/main-page/imagenDardos.png" data-toggle="modal" data-target="#modal5" class="card-img-top p-3 cursor w-75" alt="...">
                                        </div>
                                        <div class="card-body">
                                            <p class="card-text">Siéntete seguro en tu examen...</p>
                                            <a href="#" class="card-link" data-toggle="modal" data-target="#modal5">Ver más ></a>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                            <ng-template carouselSlide>
                                <div class="p-3">
                                    <div class="card shadow" style="width: 18rem; border-radius: 10px;">
                                        <div class="centrar-contenido tarjeta-pantalla-3">
                                            <img src="../../../../assets/images/main-page/Puestos.png" data-toggle="modal" data-target="#modal6" class="card-img-top p-3 cursor w-75" alt="...">
                                        </div>
                                        <div class="card-body">
                                            <p class="card-text">Para ser el mejor preparate con los mejores...</p>
                                            <a href="#" class="card-link" data-toggle="modal" data-target="#modal6">Ver más ></a>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </owl-carousel-o>
                    </div>
                </div>
            </div>
        </div>


        <div class="modo-celular ">
            <div class="mx-auto mb-3" style="max-width: 90%;">
                <h1 class="m-0 bold-title main-font-title">El pre-médico hecho a tu medida</h1>
                <div class="my-2 main-font-body mx-auto">
                    <p>La mayoria de los aceptados en medicina tomaron algún curso pre-médico, estudia con nosotros y ten acceso a las mejores herramientas, resúmenes y exámenes.</p>
                </div>
            </div>


            <div id="carouselOferta" class="carousel slide pb-5 pt-4" style="background-color: rgba(0, 170, 255, 0.2);" data-ride="carousel">
                <ol class="carousel-indicators">
                    <li data-target="#carouselOferta" data-slide-to="0" class="active"></li>
                    <li data-target="#carouselOferta" data-slide-to="1"></li>
                    <li data-target="#carouselOferta" data-slide-to="2"></li>
                    <li data-target="#carouselOferta" data-slide-to="3"></li>
                    <li data-target="#carouselOferta" data-slide-to="4"></li>
                    <li data-target="#carouselOferta" data-slide-to="5"></li>
                </ol>
                <div class="carousel-inner main-font-body">
                    <div class="carousel-item active">
                        <div class="card shadow mx-auto" style="width: 18rem; border-radius: 10px;">
                            <div class="centrar-contenido tarjeta-pantalla-3">
                                <img src="../../../../assets/images/main-page/libros.png" data-toggle="modal" data-target="#modal1" class="card-img-top p-3" alt="...">
                            </div>
                            <div class="card-body">
                                <p class="card-text">Con nosotros no necesitaras apuntes...</p>
                                <a href="#" class="card-link" data-toggle="modal" data-target="#modal1">Ver más ></a>
                            </div>
                        </div>
                    </div>
                    <div class="carousel-item">
                        <div class="card shadow mx-auto" style="width: 18rem; border-radius: 10px;">
                            <div class="centrar-contenido tarjeta-pantalla-3">
                                <img src="../../../../assets/images/main-page/medalla.png" data-toggle="modal" data-target="#modal2" class="card-img-top p-3" alt="...">
                            </div>
                            <div class="card-body">
                                <p class="card-text">Obtén una calificacion más alta que los demás...</p>
                                <a href="#" class="card-link" data-toggle="modal" data-target="#modal2">Ver más ></a>
                            </div>
                        </div>
                    </div>
                    <div class="carousel-item">
                        <div class="card shadow mx-auto" style="width: 18rem; border-radius: 10px;">
                            <div class="centrar-contenido tarjeta-pantalla-3">
                                <img src="../../../../assets/images/main-page/examenReloj.png" data-toggle="modal" data-target="#modal3" class="card-img-top p-3" alt="...">
                            </div>
                            <div class="card-body">
                                <p class="card-text">Estudia lo necesario y sin perder tiempo...</p>
                                <a href="#" class="card-link" data-toggle="modal" data-target="#modal3">Ver más ></a>
                            </div>
                        </div>
                    </div>
                    <div class="carousel-item">
                        <div class="card shadow mx-auto" style="width: 18rem; border-radius: 10px;">
                            <div class="centrar-contenido tarjeta-pantalla-3">
                                <img src="../../../../assets/images/main-page/imagenCerebro.png" data-toggle="modal" data-target="#modal4" class="card-img-top p-3" alt="...">
                            </div>
                            <div class="card-body">
                                <p class="card-text">Conóce tus áreas fuertes y refuerza las débiles....</p>
                                <a href="#" class="card-link" data-toggle="modal" data-target="#modal4">Ver más ></a>
                            </div>
                        </div>
                    </div>
                    <div class="carousel-item">
                        <div class="card shadow mx-auto" style="width: 18rem; border-radius: 10px;">
                            <div class="centrar-contenido tarjeta-pantalla-3">
                                <img src="../../../../assets/images/main-page/imagenDardos.png" data-toggle="modal" data-target="#modal5" class="card-img-top p-3" alt="...">
                            </div>
                            <div class="card-body">
                                <p class="card-text">Siéntete seguro en tu examen...</p>
                                <a href="#" class="card-link" data-toggle="modal" data-target="#modal5">Ver más ></a>
                            </div>
                        </div>
                    </div>
                    <div class="carousel-item">
                        <div class="card shadow mx-auto" style="width: 18rem; border-radius: 10px;">
                            <div class="centrar-contenido tarjeta-pantalla-3">
                                <img src="../../../../assets/images/main-page/Puestos.png" data-toggle="modal" data-target="#modal6" class="card-img-top p-3" alt="...">
                            </div>
                            <div class="card-body">
                                <p class="card-text">Para ser el mejor preparate con los mejores...</p>
                                <a href="#" class="card-link" data-toggle="modal" data-target="#modal6">Ver más ></a>
                            </div>
                        </div>
                    </div>
                </div>
                <a class="carousel-control-prev" href="#carouselOferta" role="button" data-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="sr-only">Previous</span>
                </a>
                <a class="carousel-control-next" href="#carouselOferta" role="button" data-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="sr-only">Next</span>
                </a>
            </div>
        </div>
    </div>


    <!-- MODALES SECCION 3 -->
    <!-- Modal 1-->
    <div class="modal fade" id="modal1" tabindex="-1" aria-labelledby="modal1Label" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content" style="border-radius: 10px !important;">
                <div class="modal-body p-0">
                    <div class="card" style="border-radius: 10px !important;">
                        <div class="centrar-contenido tarjeta-modal">
                            <img src="../../../../assets/images/main-page/libros.png" class="card-img-top p-3 w-75" alt="...">
                        </div>
                        <div class="card-body">
                            <h4>Con nosotros no necesitaras apuntes...</h4>
                            <p>
                                ¡Hicimos todo el trabajo por ti! Hemos revisado todos los temas e hicimos resúmenes de cada uno basado en las preguntas que vendrán tu examen.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" style="width: 30%;" class=" btn btn-medprep main-font-body" data-dismiss="modal">Regresar</button>
                </div>
            </div>
        </div>
    </div>
    <!-- Modal 1-->

    <!-- Modal 2-->
    <div class="modal fade" id="modal2" tabindex="-1" aria-labelledby="modal2Label" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content" style="border-radius: 10px !important;">
                <div class="modal-body p-0">
                    <div class="card" style="border-radius: 10px !important;">
                        <div class="centrar-contenido tarjeta-modal">
                            <img src="../../../../assets/images/main-page/medalla.png" class="card-img-top p-3 w-75" alt="...">
                        </div>
                        <div class="card-body">
                            <h4>Obtén una calificacion más alta que los demás...</h4>
                            <p>
                                El 80% de los admitidos a la carrera tomaron algun tipo de curso, prepárate con los mejores.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" style="width: 30%;" class="btn btn-medprep main-font-body" data-dismiss="modal">Regresar</button>
                </div>
            </div>
        </div>
    </div>
    <!-- Modal 2-->


    <!-- Modal 3-->
    <div class="modal fade" id="modal3" tabindex="-1" aria-labelledby="modal3Label" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content" style="border-radius: 10px !important;">
                <div class="modal-body p-0">
                    <div class="card" style="border-radius: 10px !important;">
                        <div class="centrar-contenido tarjeta-modal">
                            <img src="../../../../assets/images/main-page/examenReloj.png" class="card-img-top p-3 w-75" alt="...">
                        </div>
                        <div class="card-body">
                            <h4>Estudia lo necesario y sin perder tiempo...</h4>
                            <p>
                                Te lo hacemos fácil, los conceptos más preguntados los resaltamos para identificarlos de una manera más sencilla. </p>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" style="width: 30%;" class="btn btn-medprep main-font-body" data-dismiss="modal">Regresar</button>
                </div>
            </div>
        </div>
    </div>
    <!-- Modal 3-->

    <!-- Modal 4-->
    <div class="modal fade" id="modal4" tabindex="-1" aria-labelledby="modal4Label" aria-hidden="true">
        <div class="modal-dialog  modal-dialog-centered">
            <div class="modal-content" style="border-radius: 10px !important;">
                <div class="modal-body p-0">
                    <div class="card" style="border-radius: 10px !important;">
                        <div class="centrar-contenido tarjeta-modal">
                            <img src="../../../../assets/images/main-page/imagenCerebro.png" class="card-img-top p-3 w-75" alt="...">
                        </div>
                        <div class="card-body">
                            <h4>Conóce tus áreas fuertes y refuerza las débiles...</h4>
                            <p>
                                Lo que no se mide no se mejora, aquí te diremos tus áreas débiles y fuertes para saber que debes reforzar. </p>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" style="width: 30%;" class="btn btn-medprep main-font-body" data-dismiss="modal">Regresar</button>
                </div>
            </div>
        </div>
    </div>
    <!-- Modal 4-->


    <!-- Modal 5-->
    <div class="modal fade" id="modal5" tabindex="-1" aria-labelledby="modal5Label" aria-hidden="true">
        <div class="modal-dialog  modal-dialog-centered">
            <div class="modal-content" style="border-radius: 10px !important;">
                <div class="modal-body p-0">
                    <div class="card" style="border-radius: 10px !important;">
                        <div class="centrar-contenido tarjeta-modal">
                            <img src="../../../../assets/images/main-page/imagenDardos.png" class="card-img-top p-3 w-75" alt="...">
                        </div>
                        <div class="card-body">
                            <h4>Siéntete seguro en tu examen...</h4>
                            <p>
                                La mejor forma de prepararte para un exámen es presentando uno igual. Practica todo lo que necesites con nuestros exámenes simulacro basados en preguntas de años pasados. </p>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" style="width: 30%;" class="btn btn-medprep main-font-body" data-dismiss="modal">Regresar</button>
                </div>
            </div>
        </div>
    </div>
    <!-- Modal 5-->
    <!-- Modal 6-->
    <div class="modal fade" id="modal6" tabindex="-1" aria-labelledby="modal6Label" aria-hidden="true">
        <div class="modal-dialog  modal-dialog-centered">
            <div class="modal-content" style="border-radius: 10px !important;">
                <div class="modal-body p-0">
                    <div class="card" style="border-radius: 10px !important;">
                        <div class="centrar-contenido tarjeta-modal">
                            <img src="../../../../assets/images/main-page/Puestos.png" class="card-img-top p-3 w-75" alt="...">
                        </div>
                        <div class="card-body">
                            <h4>Para ser el mejor preparate con los mejores...</h4>
                            <p>
                                El porcentaje de admisión a medicina en México es de 5%, este es el mismo porcentaje de admisión de la universidad de Harvard, solo los mejores entran. </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" style="width: 30%;" class="btn btn-medprep main-font-body" data-dismiss="modal">Regresar</button>
                </div>
            </div>
        </div>
    </div>
    <!-- Modal 6-->
    <!-- MODALES SECCION 3 -->

    <!-- SECCION 3 -->

    <!-- SECCION 4 -->
    <div class="h-100">
        <div class="modo-escritorio">
            <div class="main-image-cuatro"></div>
            <div class="">
                <h1 class="m-0 bold-title main-font-title w-100 text-center">Todo lo que necesitas en un solo lugar</h1>
                <div class="container-fluid pantalla-contenido mt-5">
                    <div class="row mx-auto" style="width: 85% !important;">
                        <div class="col-4  centrar-contenido">
                            <div class="list-group" id="list-tab" role="tablist">
                                <a class="list-group-item list-group-item-action active border-0 lista-main" id="list-resumenes-list" data-toggle="list" href="#list-resumenes" role="tab" aria-controls="resumenes"><span class="span-text">01</span>Resúmenes</a>
                                <a class="list-group-item list-group-item-action border-0 lista-main" id="list-flashcard-list" data-toggle="list" href="#list-flashcard" role="tab" aria-controls="flashcard"><span class="span-text">02</span> Flashcards</a>
                                <a class="list-group-item list-group-item-action border-0 lista-main" id="list-ejercicios-list" data-toggle="list" href="#list-ejercicios" role="tab" aria-controls="ejercicios"><span class="span-text">03</span> Ejercicios</a>
                                <a class="list-group-item list-group-item-action border-0 lista-main" id="list-examenes-list" data-toggle="list" href="#list-examenes" role="tab" aria-controls="examenes"><span class="span-text">04</span> Exámenes simulacro</a>
                                <a class="list-group-item list-group-item-action border-0 lista-main" id="list-estadisticas-list" data-toggle="list" href="#list-estadisticas" role="tab" aria-controls="estadisticas"><span class="span-text">05</span> Estadísticas</a>
                                <a class="list-group-item list-group-item-action border-0 lista-main" id="list-tips-list" data-toggle="list" href="#list-tips" role="tab" aria-controls="tips"><span class="span-text">06</span> Tips</a>
                                <a class="list-group-item list-group-item-action border-0 lista-main" id="list-ideas-list" data-toggle="list" href="#list-ideas" role="tab" aria-controls="ideas"><span class="span-text">07</span> Ideas clave</a>
                                <a class="list-group-item list-group-item-action border-0 lista-main" id="list-clases-list" data-toggle="list" href="#list-clases" role="tab" aria-controls="clases"><span class="span-text">08</span> Clases</a>
                                <div class="my-5 mx-auto">
                                    <button class="boton-main-page px-4 main-font-button shadow w-100" (click)="sendData()">Pruébalo gratis</button>
                                    <button class="boton-comprar px-4 main-font-button shadow w-100 mt-2" [routerLink]="['/information/prices']" routerLinkActive="router-link-active">Comprar</button>
                                </div>
                            </div>
                        </div>
                        <div class="col-8">
                            <div class="tab-content" id="nav-tabContent" style="height: 500px !important;">
                                <div class="tab-pane fade show active oferta-main text-center" style="height: 100% !important;" id="list-resumenes" role="tabpanel" aria-labelledby="list-resumenes-list">
                                    <div style="height: 100% !important;" class="centrar-contenido">
                                        <img src="../../../../assets/images/main-page/resumenes.png" class="img-fluid w-75" alt="">
                                    </div>
                                </div>
                                <div class="tab-pane fade oferta-main text-center" id="list-flashcard" role="tabpanel" aria-labelledby="list-flashcard-list">
                                    <div style="height: 100% !important;" class="centrar-contenido">
                                        <img src="../../../../assets/images/main-page/flashcards.png" class="img-fluid w-75" alt="">
                                    </div>
                                </div>
                                <div class="tab-pane fade oferta-main text-center" id="list-ejercicios" role="tabpanel" aria-labelledby="list-ejercicios-list">
                                    <div style="height: 100% !important;" class="centrar-contenido">
                                        <img src="../../../../assets/images/main-page/ejercicios.png" class="img-fluid w-75" alt="">
                                    </div>
                                </div>
                                <div class="tab-pane fade oferta-main text-center" id="list-examenes" role="tabpanel" aria-labelledby="list-examenes-list">
                                    <div style="height: 100% !important;" class="centrar-contenido">
                                        <img src="../../../../assets/images/main-page/examenes.png" class="img-fluid w-75" alt="">
                                    </div>
                                </div>
                                <div class="tab-pane fade oferta-main text-center" id="list-estadisticas" role="tabpanel" aria-labelledby="list-estadisticas-list">
                                    <div style="height: 100% !important;" class="centrar-contenido">
                                        <img src="../../../../assets/images/main-page/estadisticas.png" class="img-fluid w-75" alt="">
                                    </div>
                                </div>
                                <div class="tab-pane fade oferta-main text-center" id="list-tips" role="tabpanel" aria-labelledby="list-tips-list">
                                    <div style="height: 100% !important;" class="centrar-contenido">
                                        <img src="../../../../assets/images/main-page/tips.png" class="img-fluid w-75" alt="">
                                    </div>
                                </div>
                                <div class="tab-pane fade oferta-main text-center" id="list-ideas" role="tabpanel" aria-labelledby="list-ideas-list">
                                    <div style="height: 100% !important;" class="centrar-contenido">
                                        <img src="../../../../assets/images/main-page/ideas.png" class="img-fluid w-75" alt="">
                                    </div>
                                </div>
                                <div class="tab-pane fade oferta-main text-center" id="list-clases" role="tabpanel" aria-labelledby="list-clases-list">
                                    <div style="height: 100% !important;" class="centrar-contenido">
                                        <img src="../../../../assets/images/main-page/clases.png" class="img-fluid w-75" alt="">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="modo-celular mb-5">
            <div class="mx-auto mb-3" style="max-width: 90%;">
                <h1 class="m-0 bold-title main-font-title" style="width: 100%;">Todo lo que necesitas en un solo lugar</h1>
            </div>


            <div id="carouselExp" class="carousel slide main-font-body" style="background-color: rgba(0, 170, 255, 0.2);" data-ride="carousel">
                <ol class="carousel-indicators">
                    <li data-target="#carouselExp" data-slide-to="0" class="active"></li>
                    <li data-target="#carouselExp" data-slide-to="1"></li>
                    <li data-target="#carouselExp" data-slide-to="2"></li>
                    <li data-target="#carouselExp" data-slide-to="3"></li>
                    <li data-target="#carouselExp" data-slide-to="4"></li>
                    <li data-target="#carouselExp" data-slide-to="5"></li>
                    <li data-target="#carouselExp" data-slide-to="6"></li>
                </ol>
                <div class="carousel-inner py-5">
                    <div class="carousel-item active">
                        <img src="../../../../assets/images/main-page/estadisticas.png" class="d-block w-75 mx-auto" alt="...">
                        <div class="w-75 mx-auto text-center">
                            <h4>Estadísticas</h4>
                            <p>
                                Conoce tus mejores areas de estudio y enfocate en tus areas de oportunidad.
                            </p>
                        </div>
                    </div>
                    <div class="carousel-item">
                        <img src="../../../../assets/images/main-page/resumenes.png" class="d-block w-75 mx-auto" alt="...">
                        <div class="w-75 mx-auto text-center">
                            <h4>Resúmenes</h4>
                            <p>
                                Solo lo que necesitas saber y nada más.
                            </p>
                        </div>
                    </div>
                    <div class="carousel-item">
                        <img src="../../../../assets/images/main-page/ejercicios.png" class="d-block w-75 mx-auto" alt="...">
                        <div class="w-75 mx-auto text-center">
                            <h4>Ejercicios</h4>
                            <p>
                                Encuentra ejercicios de cada tema.
                            </p>
                        </div>
                    </div>
                    <div class="carousel-item">
                        <img src="../../../../assets/images/main-page/tips.png" class="d-block w-75 mx-auto" alt="...">
                        <div class="w-75 mx-auto text-center">
                            <h4>Tips</h4>
                            <p>
                                Recibe experiencia de quien ya presentó el examen.
                            </p>
                        </div>
                    </div>
                    <div class="carousel-item">
                        <img src="../../../../assets/images/main-page/ideas.png" class="d-block w-75 mx-auto" alt="...">
                        <div class="w-75 mx-auto text-center">
                            <h4>Puntos clave</h4>
                            <p>
                                Identifica facilmente donde debes enfocarte.
                            </p>
                        </div>
                    </div>
                    <div class="carousel-item">
                        <img src="../../../../assets/images/main-page/flashcards.png" class="d-block w-75 mx-auto" alt="...">
                        <div class="w-75 mx-auto text-center">
                            <h4>Flaschcards</h4>
                            <p>
                                Una imagen dice más que mil palabras.
                            </p>
                        </div>
                    </div>
                    <div class="carousel-item">
                        <img src="../../../../assets/images/main-page/clases.png" class="d-block w-75 mx-auto" alt="...">
                        <div class="w-75 mx-auto text-center">
                            <h4 class="mt-2">Clases</h4>
                            <p>
                                Clases en vivo o grabadas, dependiendo de tu plan, con profesores expertos.
                            </p>
                        </div>
                    </div>
                </div>
                <a class="carousel-control-prev" href="#carouselExp" role="button" data-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="sr-only">Previous</span>
                </a>
                <a class="carousel-control-next" href="#carouselExp" role="button" data-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="sr-only">Next</span>
                </a>
            </div>

        </div>



        <!-- SECCION 4 -->
        <!-- SECCION 5 -->
        <div class="carusel-background-uno pantalla-cinco p-4 mb-5">
            <h1 class="m-0 mb-4 bold-title main-font-title w-100 text-center">¿Qué estás esperando?</h1>
            <div class="w-100 centrar-contenido">
                <div class="m-auto ancho-inputs">
                    <div class="input-group mb-3 w-100">
                        <div class="input-group-prepend">
                            <span class="input-group-text mod-input-email" id="basic-addon1"></span>
                        </div>
                        <input type="email" name="correo" #correo="ngModel" [(ngModel)]="data.correo" class="shadow form-control input-email" placeholder="tumail@correo.com" aria-label="email" aria-describedby="basic-addon1">
                    </div>
                    <div class="input-group mb-3 w-100">
                        <div class="input-group-prepend">
                            <span class="input-group-text mod-input-email" id="basic-addon1"></span>
                        </div>
                        <input type="password" name="password" #password="ngModel" [(ngModel)]="data.password" class="shadow form-control input-email" placeholder="Contraseña" aria-label="contra" aria-describedby="basic-addon1">
                    </div>
                    <button class="shadow boton-main-page px-4 mx-auto main-font-button d-block" (click)="sendData()">Pruébalo gratis hoy</button>
                </div>
            </div>
        </div>
    </div>
    <!-- SECCION 5 -->



    <!-- SECCION 6 -->
    <app-faq></app-faq>
    <!-- SECCION 6 -->

</div>