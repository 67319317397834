import { ComunicationService } from 'src/app/services/comunication.service';
import { Cronometro } from './../../../../models/cronometro';
import { ExamenPersonalizado } from './../../../../models/examen-personalizado';
import { ExamenDiagnostico } from './../../../../models/examen-diagnostico';
import { ServicioToastr } from './../../../../services/toastr.service';
import { Examen } from './../../../../models/examen';
import { AfterViewChecked, Component, ElementRef, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ServicioExamen } from 'src/app/services/examen.service';
import { ServicioUsuario } from 'src/app/services/usuario.service';
import { PREGUNTAS } from '../../../../services/enums/preguntas-materia.enum';
import { TIPO_EXAMEN } from '../../../../services/enums/tipo-examen.enum';
import { MATERIAS } from 'src/app/services/enums/materias.enum';
import { NUMERO_PREGUNTAS_POR_EXAMEN } from 'src/app/services/enums/numero-preguntas-por-examen';
import { ExamenSimulacro } from 'src/app/models/examen-simulacro';

@Component({
  selector: 'app-exam',
  templateUrl: './exam.component.html',
  styleUrls: ['./exam.component.sass', './exam.component.css']
})
export class ExamComponent implements OnInit, AfterViewChecked, OnDestroy {
  @ViewChild("barraDeProgreso") barraDeProgreso: ElementRef;

  public token: string;
  public preguntasList: Examen[] = [];
  public preguntasExamenPersonalizadoList: any[] | undefined = undefined;

  public indice: number = 0;  // Indice por materia
  public indiceRespuestas: number = 0; //Indice de todas las respuestas
  public indiceMaterias: number = 0; // Indice que indica la materia actual
  public indiceGeneral: number = 0; // Indice general para que el usuario lo vea

  public totalPreguntas: number = 0;

  public pregunta_especial_flag: boolean = false;
  public lectura_terminada_flag: boolean = false;
  public finalizado_flag: boolean = false;
  public examenCompletado: boolean = false;

  public respuestas: any[][] = []; // Respuesta | preguntaContestada | materia

  public tipoExamen: number = 1;

  public espanol: number = 0;
  public matematicas: number = 0;
  public quimica: number = 0;
  public biologia: number = 0;
  public fisica: number = 0;
  public premedicina: number = 0;

  public calificacionFinal: number = 0;
  public aciertos: number = 0;
  public errores: number = 0;
  public tiempo: string = "0:00:00";
  public tiempoPorPregunta: string = "0:00";

  public cronometroInfo: Cronometro = new Cronometro(false, 0, 0);

  public celularFlag: boolean = false;

  public loading: boolean = true;
  public preguntasError: string | null = null;

  public mensaje1: string | null = null;
  public mensaje2: string | null = null;

  constructor(private _servicioExamen: ServicioExamen,
    private _servicioUsuario: ServicioUsuario,
    private _servicioToastr: ServicioToastr,
    private _router: Router,
    private _comunicationService: ComunicationService) {

    this.token = this._servicioUsuario.getToken();
  }

  ngOnDestroy(): void {
    this.cronometroInfo.activado = false;
    this.cronometroInfo.tiempo = 0;
  }

  ngAfterViewChecked(): void {
    this.actualizarrBarraDeProgreso();
  }

  ngOnInit(): void {
    if (window.screen.width < 1000) this.celularFlag = true;
    //Se crea el examen mandando los ids de las materias seleccionadas
    this.tipoExamen = +localStorage.getItem('tipoExamen');
    localStorage.removeItem('tipoExamen');

    let materias: string[] = [];
    let datosParaExamenPersonalizado: any;
    switch (this.tipoExamen) {
      case TIPO_EXAMEN.SIMULACRO:
        this.totalPreguntas = NUMERO_PREGUNTAS_POR_EXAMEN.SIMULACRO;
        this.initArregloRespuestas();
        materias = this.obtenerDatosParaExamenSimulacro();
        this.generarExamenSimulacro(materias);
        break;
      case TIPO_EXAMEN.DIAGNOSTICO:
        this.totalPreguntas = NUMERO_PREGUNTAS_POR_EXAMEN.DIAGNOSTICO;
        this.initArregloRespuestas();
        materias = JSON.parse(localStorage.getItem('materias'));
        this.generarExamenMiniSimulacro(materias);
        break;
      case TIPO_EXAMEN.PERSONALIZADO:
        datosParaExamenPersonalizado = this.obtenerDatosParaExamenPersonalizado();
        this.generarExamenPersonalizado(datosParaExamenPersonalizado.temas, datosParaExamenPersonalizado.numPreguntas);
        break;
      case TIPO_EXAMEN.PREDETERMINADO:
        this.totalPreguntas = NUMERO_PREGUNTAS_POR_EXAMEN.DIAGNOSTICO;
        this.initArregloRespuestas();
        this.generarExamenPredeterminado();
        break;
      default:
        this._router.navigate(['/']);
        break;
    }

    this.cronometroInfo.activado = true;
    this.cronometroInfo.tipoExamen = this.tipoExamen;
    this.recibirDatos();
    this.enviarDatos();

  }

  recibirDatos() {
    this._comunicationService.enviarObjetoCronometroObservable.subscribe(res => {
      if (res) {
        this.cronometroInfo = res;
      }
    });
  }

  enviarDatos() {
    this._comunicationService.enviarDataCronometro(this.cronometroInfo);
  }

  obtenerDatosParaExamenSimulacro(): string[] {
    let materias = JSON.parse(localStorage.getItem('materias'));
    if (!materias || materias.length === 0) {
      this._router.navigate(['/']);
    }
    localStorage.removeItem('materias');
    return materias;
  }

  obtenerDatosParaExamenPersonalizado(): any {
    let temas: string[] = JSON.parse(localStorage.getItem('temas'));
    let numPreguntas = JSON.parse(localStorage.getItem('numPreguntas'));
    localStorage.removeItem('temas');
    localStorage.removeItem('numPreguntas');
    return {
      temas,
      numPreguntas
    };
  }

  initArregloRespuestas(): void {
    for (let i = 0; i < this.totalPreguntas; i++) {
      this.respuestas.push([false, -1, ""]);
    }
  }

  generarExamenPersonalizado(temas, numPreguntas): void {
    this._servicioExamen.generarExamenPersonalizado(this.token, temas, numPreguntas).subscribe({
      next: (result) => {
        this.preguntasExamenPersonalizadoList = result.preguntas;
        console.log(this.preguntasExamenPersonalizadoList);

        this.totalPreguntas = this.preguntasExamenPersonalizadoList.length;
        this.initArregloRespuestas();
        this.actualizarrBarraDeProgreso();
        this.loading = false;

      }, error: (e) => {
        console.log(e);

      }
    });
  }

  generarExamenSimulacro(materias: string[]): void {
    this._servicioExamen.generarExamenSimulacro(this.token, materias).subscribe({
      next: (result) => {
        this.preguntasList = result.examen;
        this.actualizarrBarraDeProgreso();
        this.loading = false;
      }, error: (e) => {
        console.log(e);

      }
    });
  }

  generarExamenMiniSimulacro(materias: string[]): void {
    this._servicioExamen.generarExamenMiniSimulacro(this.token, materias).subscribe({
      next: (result) => {
        this.preguntasList = result.examen;
        this.actualizarrBarraDeProgreso();
        this.loading = false;

      }, error: (e) => {
        console.log(e);

      }
    });
  }

  generarExamenPredeterminado(): void {
    this._servicioExamen.generarExamenPredeterminado().subscribe({
      next: (result) => {
        this.preguntasList = result.examen;
        this.actualizarrBarraDeProgreso();
        this.loading = false;

      }, error: (e) => {
        console.log(e);

      }
    });
  }

  revisarRespuesta(esCorrecta: string, indiceRespuesta: number): void {
    if (!this.examenCompletado) {
      this.respuestas[this.indiceRespuestas][0] = (esCorrecta === 'true');
      this.respuestas[this.indiceRespuestas][1] = indiceRespuesta;

      if (this.tipoExamen === TIPO_EXAMEN.SIMULACRO || this.tipoExamen === TIPO_EXAMEN.DIAGNOSTICO) {
        this.respuestas[this.indiceRespuestas][2] = this.preguntasList[this.indiceMaterias].materia;
      }
    }
  }

  avanzar(): void {
    this.preguntasError = null;
    if (!(this.pregunta_especial_flag && !this.lectura_terminada_flag) && this.respuestas[this.indiceRespuestas][1] === -1) {
      this.preguntasError = "Selecciona una respuesta para avanzar.";
      return;
    }

    //2.- Si el modo pregunta especial esta activado y se cambia de pregunta, se concluye que ya se leyó
    if (this.pregunta_especial_flag && !this.lectura_terminada_flag && this.indice === 0) {
      this.lectura_terminada_flag = true;
    } else {
      // Los indices siempre avanzan, excepto cuando se pasa de la lectura a la pregunta, ya que la lectura no
      // cuenta como pregunta
      this.indiceGeneral++;
      this.indice++;
      this.indiceRespuestas++;
    }

    this.actualizarrBarraDeProgreso();

    switch (this.tipoExamen) {
      case TIPO_EXAMEN.SIMULACRO:
        this.revisarIndiceExamenSimulacro();
        break;
      case TIPO_EXAMEN.DIAGNOSTICO:
        this.revisarIndiceExamenMiniSimulacro();
        break;
      case TIPO_EXAMEN.PREDETERMINADO:
        this.revisarIndiceExamenMiniSimulacro();
        break;
    }
  }

  retroceder(): void {
    switch (this.tipoExamen) {
      case TIPO_EXAMEN.SIMULACRO:
        this.retrocederIndiceExamenSimulacro();
        break;
      case TIPO_EXAMEN.DIAGNOSTICO:
        this.retrocederIndiceExamenMiniSimulacro();
        break;
      case TIPO_EXAMEN.PREDETERMINADO:
        this.retrocederIndiceExamenMiniSimulacro();
        break;
      case TIPO_EXAMEN.PERSONALIZADO:
        this.retrocederExamenPersonalizado();
        break;
    }

    this.actualizarrBarraDeProgreso();
  }

  retrocederExamenPersonalizado(): void {
    this.indice--;
    this.indiceRespuestas--;
    this.indiceGeneral--;
  }

  retrocederIndiceExamenMiniSimulacro(): void {
    if (this.indice === 0 && this.indiceMaterias > 0) {
      this.indice = PREGUNTAS.MINI_SIMULACRO - 1;
      this.indiceMaterias--

    } else {
      this.indice--;
    }

    this.indiceRespuestas--;
    this.indiceGeneral--;
  }


  retrocederIndiceExamenSimulacro(): void {
    if (this.indice === 0 && this.indiceMaterias > 0) {

      switch (this.indiceMaterias) {
        case 1:
          this.indice = PREGUNTAS.ESPAÑOL_ESPECIAL - 1;
          this.indiceGeneral = (PREGUNTAS.ESPAÑOL + PREGUNTAS.ESPAÑOL_ESPECIAL) - 1;
          this.pregunta_especial_flag = true;
          break;
        case 2:
          this.indice = PREGUNTAS.MATEMATICAS - 1;
          this.indiceGeneral = (PREGUNTAS.ESPAÑOL + PREGUNTAS.ESPAÑOL_ESPECIAL + PREGUNTAS.MATEMATICAS) - 1;
          break;
        case 3:
          this.indice = PREGUNTAS.QUIMICA - 1;
          this.indiceGeneral = (PREGUNTAS.ESPAÑOL + PREGUNTAS.ESPAÑOL_ESPECIAL + PREGUNTAS.MATEMATICAS + PREGUNTAS.QUIMICA) - 1;
          break;
        case 4:
          this.indice = PREGUNTAS.BIOLOGIA - 1;
          this.indiceGeneral = (PREGUNTAS.ESPAÑOL + PREGUNTAS.ESPAÑOL_ESPECIAL + PREGUNTAS.MATEMATICAS + PREGUNTAS.QUIMICA + PREGUNTAS.BIOLOGIA) - 1;
          break;
      }
      this.indiceRespuestas--;
      this.indiceMaterias--

    } else {

      if (this.indice === 0 && this.pregunta_especial_flag && this.lectura_terminada_flag) {
        this.lectura_terminada_flag = false;
        return;
      }

      if (this.indice === 0 && this.pregunta_especial_flag && !this.lectura_terminada_flag) {
        this.pregunta_especial_flag = false;
        this.indice = PREGUNTAS.ESPAÑOL - 1;
        this.indiceRespuestas = PREGUNTAS.ESPAÑOL - 1;
        this.indiceGeneral = PREGUNTAS.ESPAÑOL - 1;

      } else {
        this.indice--;
        this.indiceGeneral--;
        this.indiceRespuestas--;
      }

    }
  }

  revisarIndiceExamenMiniSimulacro(): void {
    if (this.indice >= (PREGUNTAS.MINI_SIMULACRO)) {
      this.indiceMaterias++;
      this.indice = 0;
    }
  }

  revisarIndiceExamenSimulacro(): void {

    // 1.- Si terminan las preguntas ordinarias de español se activa el modo pregunta especial
    if (this.indiceMaterias === 0 && !this.lectura_terminada_flag && this.indice >= (PREGUNTAS.ESPAÑOL)) {
      this.pregunta_especial_flag = true;
      this.indice = 0;
    }

    //3.- Al terminar el modo pregunta esepcial, se cambia la bandera y se avanza de materia
    if (this.indiceMaterias === 0 && this.lectura_terminada_flag && this.indice >= (PREGUNTAS.ESPAÑOL_ESPECIAL)) {
      this.pregunta_especial_flag = false;
      this.indiceMaterias++;
      this.indice = 0;
    }
    if (this.indiceMaterias === 1 && this.indice >= (PREGUNTAS.MATEMATICAS)) {
      this.indiceMaterias++;
      this.indice = 0;
    }
    if (this.indiceMaterias === 2 && this.indice >= (PREGUNTAS.QUIMICA)) {
      this.indiceMaterias++;
      this.indice = 0;
    }
    if (this.indiceMaterias === 3 && this.indice >= (PREGUNTAS.BIOLOGIA)) {
      this.indiceMaterias++;
      this.indice = 0;
    }
    if (this.indiceMaterias === 4 && this.indice >= (PREGUNTAS.PRE_MEDICINA)) {
      this.indiceMaterias++;
      this.indice = 0;
    }
  }

  actualizarrBarraDeProgreso(): void {
    try {
      if (!this.finalizado_flag) {
        let ancho = ((this.indiceGeneral + 1) / this.totalPreguntas) * 100;
        this.barraDeProgreso.nativeElement.style.width = `${ancho}%`;
      }
    } catch (error) {

    }
  }

  terminarExamen(): void {
    this.finalizado_flag = true;
    this.examenCompletado = true;
    let terminado = true;
    this.respuestas.forEach(elem => {
      if (elem[1] === -1) {
        terminado = false;
      }
    });

    if (!terminado) {
      this.finalizado_flag = false;
      return;
    }

    this.cronometroInfo.activado = false;

    switch (this.tipoExamen) {
      case TIPO_EXAMEN.PERSONALIZADO:
        this.terminarExamenPersonalizado();
        break;
      case TIPO_EXAMEN.DIAGNOSTICO:
        this.terminarExamenMiniSimulacro();
        break;
      case TIPO_EXAMEN.PREDETERMINADO:
        this.terminarExamenMiniSimulacro();
        break;
      case TIPO_EXAMEN.SIMULACRO:
        this.terminarExamenSimulacro();
        break;
    }

  }

  terminarExamenSimulacro(): void {
    let califEspanol: number = 0, califMatematicas: number = 0, califBiologia: number = 0, califQuimica: number = 0, califFisica: number = 0, califPreMedicina: number = 0, total: number = 0;
    this.respuestas.forEach(elem => {

      if (elem[0]) {
        total++;
        switch (elem[2]) {
          case MATERIAS.ESPAÑOL:
            califEspanol++;
            break;
          case MATERIAS.MATEMATICAS:
            califMatematicas++;
            break;
          case MATERIAS.BIOLOGIA:
            califBiologia++;
            break;
          case MATERIAS.QUIMICA:
            califQuimica++;
            break;
          case MATERIAS.FISICA:
            califFisica++;
            break;
          case MATERIAS.PREMEDICINA:
            califPreMedicina++;
            break;
        }

      }

    });

    let tiempoTranscurrido = 14400 - this.cronometroInfo.tiempo;
    this.calificacionFinal = (total / this.totalPreguntas) * 100;
    this.aciertos = total;
    this.errores = this.totalPreguntas - total;
    this.tiempoPorPregunta = this.convertirSegundosHMS(tiempoTranscurrido / this.totalPreguntas);
    this.tiempo = this.convertirSegundosHMS(tiempoTranscurrido);

    let examenCalif: ExamenSimulacro = {
      promedio: this.calificacionFinal,
      espanol: califEspanol,
      matematicas: califMatematicas,
      biologia: califBiologia,
      quimica: califQuimica,
      fisica: califFisica,
      preMedicina: califPreMedicina,
      tiempo: this.cronometroInfo.tiempo
    }

    this._servicioExamen.finalizarExamenSimulacro(examenCalif, this.token).subscribe({
      next: (result) => {
        this._servicioToastr.showSuccess("Calificación guardada con éxito");
        // this._router.navigate(["exam-stats"])
      }, error: (error) => {
        this._servicioToastr.showError('Error al guardar las calificaciones, puede revisar sus respuestas pero no se guardarán.');
      }
    });

  }

  terminarExamenPersonalizado(): void {
    let correctas = 0;
    this.respuestas.forEach(respuesta => {
      if (respuesta[0]) {
        correctas++;
      }

    });

    this.calificacionFinal = (correctas / this.totalPreguntas) * 100;
    this.aciertos = correctas;
    this.errores = this.totalPreguntas - correctas;
    this.tiempoPorPregunta = this.convertirSegundosHMS(this.cronometroInfo.tiempo / this.totalPreguntas);
    this.tiempo = this.convertirSegundosHMS(this.cronometroInfo.tiempo);


    let examenCalif: ExamenPersonalizado = {
      promedio: this.calificacionFinal
    };

    this._servicioExamen.finalizarExamenPersonalizado(examenCalif, this.token).subscribe({
      next: (result) => {
        this._servicioToastr.showSuccess("Calificación guardada con éxito");
        // this._router.navigate(["exam-stats"])
      }, error: (error) => {
        this._servicioToastr.showError('Error al guardar las calificaciones, puede revisar sus respuestas pero no se guardarán.');
      }
    });
  }

  terminarExamenMiniSimulacro(): void {
    let califEspanol: number = 0, califMatematicas: number = 0, califBiologia: number = 0, califQuimica: number = 0, califFisica: number = 0, califPreMedicina: number = 0, total: number = 0;
    this.respuestas.forEach(elem => {
      if (elem[0]) {
        total++;
        switch (elem[2]) {
          case MATERIAS.ESPAÑOL:
            califEspanol++;
            break;
          case MATERIAS.MATEMATICAS:
            califMatematicas++;
            break;
          case MATERIAS.BIOLOGIA:
            califBiologia++;
            break;
          case MATERIAS.QUIMICA:
            califQuimica++;
            break;
          case MATERIAS.FISICA:
            califFisica++;
            break;
          case MATERIAS.PREMEDICINA:
            califPreMedicina++;
            break;
        }

      }

    });

    this.calificacionFinal = (total / this.totalPreguntas) * 100;
    this.aciertos = total;
    this.errores = this.totalPreguntas - total;
    this.tiempoPorPregunta = this.convertirSegundosHMS(this.cronometroInfo.tiempo / this.totalPreguntas);
    this.tiempo = this.convertirSegundosHMS(this.cronometroInfo.tiempo);

    if (this.tipoExamen !== TIPO_EXAMEN.PREDETERMINADO) {
      let examenCalif: ExamenDiagnostico = {
        promedio: this.calificacionFinal,
        espanol: califEspanol,
        matematicas: califMatematicas,
        biologia: califBiologia,
        quimica: califQuimica,
        fisica: califFisica,
        preMedicina: califPreMedicina,
      }

      this._servicioExamen.finalizarExamenDiagnostico(examenCalif, this.token).subscribe({
        next: (result) => {
          this._servicioToastr.showSuccess("Calificación guardada con éxito");
          // this._router.navigate(["exam-stats"])
        }, error: (error) => {
          this._servicioToastr.showError('Error al guardar las calificaciones, puede revisar sus respuestas pero no se guardarán.');
        }
      });
    } else {
      if (this.calificacionFinal < 85) {
        this.mensaje1 = "Tus probabilidades de pasar son bajas";
        this.mensaje2 = "¿Quieres saber como le hacen los mejores?";
      } else {
        this.mensaje1 = "¡No te confíes!";
        this.mensaje2 = "Refuerza tus conocimientos con los mejores";
      }
    }

  }



  convertirSegundosHMS(segundosTotales: number): string {
    let segundos = 0, minutos = 0, horas = 0;
    if (segundosTotales > 60) {
      minutos = Math.floor(segundosTotales / 60);
      segundos = segundosTotales % 60;
    } else {
      segundos = segundosTotales;
    }

    if (minutos > 60) {
      horas = Math.floor(minutos / 60);
      minutos = minutos % 60;
    }


    return ((horas < 10) ? "0" : "") + horas + ":" + ((minutos < 10) ? "0" : "") + minutos + ":" + ((segundos < 10) ? "0" : "") + segundos.toFixed(0);
  }

  irAPregunta(indice: number): void {
    this.finalizado_flag = false;
    switch (this.tipoExamen) {
      case TIPO_EXAMEN.PERSONALIZADO:
        this.retrocederAPreguntaPersonalizado(indice);
        break;
      case TIPO_EXAMEN.DIAGNOSTICO:
        this.retrocederAPreguntaDiagnostico(indice);
        break;
      case TIPO_EXAMEN.PREDETERMINADO:
        this.retrocederAPreguntaDiagnostico(indice);
        break;
      case TIPO_EXAMEN.SIMULACRO:
        this.retrocederAPreguntaSimulacro(indice);
        break;
    }
  }

  retrocederAPreguntaSimulacro(pregunta: number): void {
    let limiteMateria1 = PREGUNTAS.ESPAÑOL;
    let limiteMateria1Especal = PREGUNTAS.ESPAÑOL + PREGUNTAS.ESPAÑOL_ESPECIAL;
    let limiteMateria2 = PREGUNTAS.ESPAÑOL + PREGUNTAS.ESPAÑOL_ESPECIAL + PREGUNTAS.MATEMATICAS;
    let limiteMateria3 = PREGUNTAS.ESPAÑOL + PREGUNTAS.ESPAÑOL_ESPECIAL + PREGUNTAS.MATEMATICAS + PREGUNTAS.BIOLOGIA;
    let limiteMateria4 = PREGUNTAS.ESPAÑOL + PREGUNTAS.ESPAÑOL_ESPECIAL + PREGUNTAS.MATEMATICAS + PREGUNTAS.BIOLOGIA + PREGUNTAS.FISICA;
    let limiteMateria5 = PREGUNTAS.ESPAÑOL + PREGUNTAS.ESPAÑOL_ESPECIAL + PREGUNTAS.MATEMATICAS + PREGUNTAS.BIOLOGIA + PREGUNTAS.FISICA + PREGUNTAS.QUIMICA;

    if (pregunta < limiteMateria1) {
      this.indiceMaterias = 0
      this.indice = pregunta;

    } else if (pregunta < limiteMateria1Especal) {
      this.lectura_terminada_flag = true;
      this.pregunta_especial_flag = true;
      this.indiceMaterias = 0;
      this.indice = pregunta - limiteMateria1;

    } else if (pregunta < limiteMateria2) {
      this.indiceMaterias = 1;
      this.indice = pregunta - limiteMateria1Especal;

    } else if (pregunta < limiteMateria3) {
      this.indiceMaterias = 2;
      this.indice = pregunta - limiteMateria2;

    } else if (pregunta < limiteMateria4) {
      this.indiceMaterias = 3;
      this.indice = pregunta - limiteMateria3;

    } else if (pregunta < limiteMateria5) {
      this.indiceMaterias = 4;
      this.indice = pregunta - limiteMateria4;
    }
    this.indiceGeneral = pregunta;
    this.indiceRespuestas = pregunta;

  }

  retrocederAPreguntaDiagnostico(pregunta: number): void {
    this.indiceGeneral = pregunta;
    this.indiceMaterias = Math.floor(pregunta / PREGUNTAS.MINI_SIMULACRO);
    this.indice = (pregunta % PREGUNTAS.MINI_SIMULACRO);
    this.indiceRespuestas = pregunta;
  }

  retrocederAPreguntaPersonalizado(pregunta: number): void {
    this.indiceGeneral = pregunta;
    this.indice = pregunta;
    this.indiceRespuestas = pregunta;
  }

  toggleFinalizar(): void {
    if (this.finalizado_flag) {
      this.finalizado_flag = false;
    } else {
      this.finalizado_flag = true;
    }

    this.indice = 0;
    this.indiceGeneral = 0;
    this.indiceMaterias = 0;
    this.indiceRespuestas = 0;
    this.pregunta_especial_flag = false;
    this.lectura_terminada_flag = false;
  }

  finalizar(): void {
    if (this.tipoExamen === TIPO_EXAMEN.PREDETERMINADO) {
      this.irALanding();

    } else {
      this._router.navigate(['/content/exam-stats']);
    }
  }

  irALanding(): void {
    this._router.navigate(['campaign', 2]);

  }

}
