import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { ServicioUsuario } from '../usuario.service';

@Injectable()
export class SesionGuard implements CanActivate {

    constructor(
        private _servicioUsuario: ServicioUsuario,
        private _router: Router
    ) {

    }

    canActivate() {
        let identity = this._servicioUsuario.getIdentity();

        if (identity && identity.correo_verificado) {
            return true;
        } else if (identity && !identity.correo_verificado){
            this._router.navigate(['/auth/login']);
            return false;
        } else {
            this._router.navigate(['/auth/login']);
            return false;
        }

    }
}
