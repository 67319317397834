import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { AppRoutingModule } from './app.routing';
import { AppComponent } from './app.component';
import { registerLocaleData } from '@angular/common';

import localEs from '@angular/common/locales/es';
registerLocaleData(localEs, 'es');

//PIPES
import { SafePipe } from './services/pipes/safe.pipe';
//import { NumberFormatPipe } from './services/pipes/number-format.pipe';

//SERVICIOS
import { KatexModule } from 'ng-katex';

//COMPONENTES
import { PruebasComponent } from './components/pruebas/pruebas.component';
import { ReconCampaignComponent } from './components/landing-pages/recon-campaign/recon-campaign.component';
import { NavbarComponent } from './components/principal/navbar/navbar.component';
import { FooterComponent } from './components/principal/footer/footer.component';
import { MainPageComponent } from './components/principal/main-page/main-page.component';
import { SummaryComponent } from './components/content/summary/summary.component';
import { ExamComponent } from './components/content/exam/exam-view/exam.component';

//MODULES
import { CompanyInfoModule } from './components/company-info/company-info.module';
import { YouTubePlayerModule } from '@angular/youtube-player';

//SERVICIOS
import { ServicioUsuario } from './services/usuario.service';
import { ServicioTemas } from './services/temas.service';
import { ServicioMaterias } from './services/materias.service';
import { ServicioResumen } from './services/resumen.service';
import { ServicioFrases } from './services/frases.service';
import { ServicioExamen } from './services/examen.service';
import { ComunicationService } from './services/comunication.service';
import { ServicioClases } from './services/clases.service';
import { ServicioPreguntas } from './services/preguntas.service';
import { ServicioEstadisticas } from './services/estadisticas.service';
import { ServicioGrupoTemas } from './services/grupo-temas.service';
import { ServicioToastr } from './services/toastr.service';
import { ServicioFlashcard } from './services/flascard.service';

//GUARDS
import { UsuarioGuard } from './services/guards/usuario.guard';
import { SesionGuard } from './services/guards/sesion.guard';

@NgModule({
  declarations: [
    AppComponent,
    PruebasComponent,
    ReconCampaignComponent,
    NavbarComponent,
    FooterComponent,
    MainPageComponent,
    SafePipe,
    //NumberFormatPipe,
    SummaryComponent,
    ExamComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    KatexModule,
    CarouselModule,
    CompanyInfoModule,
    YouTubePlayerModule
  ],
  providers: [
    ServicioUsuario,
    ServicioTemas,
    ServicioMaterias,
    ServicioResumen,
    ServicioFrases,
    ServicioExamen,
    ServicioToastr,
    ServicioPreguntas,
    ServicioGrupoTemas,
    ServicioFlashcard,
    UsuarioGuard,
    ComunicationService,
    SesionGuard,
    ServicioClases,
    ServicioEstadisticas,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: LOCALE_ID, useValue: 'es' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
