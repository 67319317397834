import { Component, DoCheck, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { ServicioUsuario } from './services/usuario.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass', './app.component.css']
})
export class AppComponent implements OnInit, DoCheck {
  title = 'Medprep-Frontend';
  public token: string;
  public identity: any;
  constructor(
    private _servicioUsuario: ServicioUsuario,
    private _router: Router
  ) {
    this.identity = this._servicioUsuario.getIdentity();
    this.token = this._servicioUsuario.getToken();

    const navEndEvent$ = this._router.events.pipe(
      filter(e => e instanceof NavigationEnd)
    );
    navEndEvent$.subscribe((e: NavigationEnd) => {
      gtag('config', 'G-NXQP0LWKFC', {
        'page_path': e.urlAfterRedirects
      });
    });

  }

  ngDoCheck() {
    this.identity = this._servicioUsuario.getIdentity();
  }

  ngOnInit(): void {
    if (this.token) {
      this.comprobarSesion();
    }
  }

  revisarPlan(): void {
    this._servicioUsuario.revisarPlan(this.token).subscribe({
      next: (response) => {
        if (response && !response.activo) {
          this._servicioUsuario.actualizarPlan(this.token);
          this.identity.cuenta[0].tipo = "Gratis";
          localStorage.removeItem("identity");
          localStorage.setItem("identity", JSON.stringify(this.identity));
        }
      }
    })
  }

  comprobarSesion(): void {
    this._servicioUsuario.comprobarSesion().subscribe({
      next: (response) => {
        if (!response) {
          console.log('Error en el servidor');
        }

        this.revisarPlan();
      }, error: (e) => {
        if (e) {
          $('#modalSesion').modal('show');
          localStorage.removeItem('identity');
          localStorage.removeItem('token');
        }
      }
    });

  }

  login() {
    $('#modalSesion').modal('hide');
    this._router.navigate(['/auth/login']);
  }

}

