import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Usuario } from '../models/usuario';
import { GLOBAL } from './global';
import { RUTAS } from './enums/rutas.enum';

@Injectable()
export class ServicioPreguntas {
  public url: string;

  constructor(
    public _http: HttpClient
  ) {
    this.url = GLOBAL.url + RUTAS.PREGUNTA;
  }

  obtenerPreguntasPorTema(token: string, tema: string): Observable<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', token);;

    return this._http.get(this.url + 'obtener-preguntas-tema/' + tema, { headers: headers });
  }

  borrarPregunta(token: string, id: string): Observable<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', token);;

    return this._http.delete(this.url + 'borrar-pregunta/' + id, { headers: headers });
  }

  guardarPregunta(data: FormData): Observable<any> {
    return this._http.post(this.url + 'guardar-pregunta', data);
  }

  guardarImagenPregunta(data: FormData): Observable<any> {
    return this._http.post(this.url + 'agregar-imagen-pregunta', data);
  }

  revisarExamenesPresentados(token: string): Promise<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', token);;

    return this._http.get(this.url + 'revisar-examenes-presentados', { headers: headers }).toPromise();
  }


}
