import { Cronometro } from './../models/cronometro';
import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';

@Injectable()
export class ComunicationService {

    public cronometro: Cronometro;
    private enviarObjetoCronometroSubject = new Subject<Cronometro>();
    public enviarObjetoCronometroObservable = this.enviarObjetoCronometroSubject.asObservable();

    constructor() { }

    enviarDataCronometro(data: Cronometro) {
        this.cronometro = data;
        this.enviarObjetoCronometroSubject.next(this.cronometro);
    }


}
