import { Cronometro } from './../../../models/cronometro';
import { ComunicationService } from 'src/app/services/comunication.service';
import { Component, OnInit, DoCheck, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ServicioUsuario } from 'src/app/services/usuario.service';
import { TIPO_EXAMEN } from '../../../services/enums/tipo-examen.enum';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.sass', './navbar.component.css']
})
export class NavbarComponent implements OnInit, DoCheck, OnDestroy {

  public identity;
  public examen: boolean = true;

  public s: number;
  public m;
  public h;
  public total;
  public cronometro;
  public contador;
  public cronometroInfo: Cronometro = new Cronometro(false, 0, 0);
  public escritorioToggle: boolean = true;

  constructor(
    private _router: Router,
    private _servicioUsuario: ServicioUsuario,
    private _comunicationService: ComunicationService
  ) {
    this.identity = this._servicioUsuario.getIdentity();
    this.contador = false;
    this.s = 0;
    this.m = 0;
    this.h = 0;
    this.cronometroInfo.tiempo = 0; //4 horas
    this.cronometroInfo.activado = false;
  }

  ngOnDestroy(): void {
    clearInterval(this.cronometro);
  }

  ngOnInit(): void {
    if (window.screen.width < 1000) this.escritorioToggle = false;
    this.recibirDatos();
    this.enviarDatos();
    this.identity = this._servicioUsuario.getIdentity();
  }

  ngDoCheck() {
    this.identity = this._servicioUsuario.getIdentity();
  }

  cerrarSesion() {
    localStorage.removeItem('identity');
    localStorage.removeItem('token');
    this._router.navigate(['/']);
  }

  recibirDatos() {
    this._comunicationService.enviarObjetoCronometroObservable.subscribe(res => {
      if (res) {
        this.cronometroInfo = res;
        if (this.cronometroInfo.activado) {
          if (this.cronometroInfo.tipoExamen === TIPO_EXAMEN.SIMULACRO) {
            this.cronometroInfo.tiempo = 14400; //4 horas
            this.h = 4;
            this.cronometro = setInterval(() => { this.iniciarCuentaRegresiva(); }, 1000);
          } else {
            this.s = 0;
            this.m = 0;
            this.h = 0;
            this.cronometroInfo.tiempo = 0;
            this.cronometro = setInterval(() => { this.iniciarCuentaProgresiva(); }, 1000);

          }
        } else {
          clearInterval(this.cronometro);
        }
      }
    });
  }

  enviarDatos() {
    this._comunicationService.enviarDataCronometro(this.cronometroInfo);
  }

  iniciarCuentaProgresiva() {
    if (!this.cronometroInfo.activado) {
      clearInterval(this.cronometro);
    }

    this.s++;
    if (this.s > 59) {
      this.s = 0;
      this.m++;

      if (this.m > 59) {
        this.m = 0;
        this.h++

      }
    }
    let hAux, mAux, sAux;
    this.cronometroInfo.tiempo++;

    if (this.s > 59) { this.m++; this.s = 0; }
    if (this.m > 59) { this.h++; this.m = 0; }
    if (this.h > 24) { this.h = 0; }

    if (this.s < 10) { sAux = "0" + this.s; } else { sAux = this.s; }
    if (this.m < 10) { mAux = "0" + this.m; } else { mAux = this.m; }
    if (this.h < 10) { hAux = "0" + this.h; } else { hAux = this.h; }


    try {
      if (this.escritorioToggle) {
        document.getElementById("hms").innerHTML = hAux + ":" + mAux + ":" + sAux;
      } else {
        document.getElementById("hms1").innerHTML = hAux + ":" + mAux + ":" + sAux;
      }
    } catch (e) {

    }
  }

  //CRONOMETRO
  iniciarCuentaRegresiva() {
    if (!this.cronometroInfo.activado) {
      clearInterval(this.cronometro);
    }

    this.s--;
    if (this.s < 0) {
      this.s = 59;
      this.m--;

      if (this.m < 0) {
        this.m = 59;

        this.h--

        if (this.h < 0) {
          this.h = 0;
        }
      }
    }
    let hAux, mAux, sAux;
    this.cronometroInfo.tiempo--;

    if (this.s > 59) { this.m++; this.s = 0; }
    if (this.m > 59) { this.h++; this.m = 0; }
    if (this.h > 24) { this.h = 0; }

    if (this.s < 10) { sAux = "0" + this.s; } else { sAux = this.s; }
    if (this.m < 10) { mAux = "0" + this.m; } else { mAux = this.m; }
    if (this.h < 10) { hAux = "0" + this.h; } else { hAux = this.h; }


    try {
      if (this.escritorioToggle) {
        document.getElementById("hms").innerHTML = hAux + ":" + mAux + ":" + sAux;
      } else {
        document.getElementById("hms1").innerHTML = hAux + ":" + mAux + ":" + sAux;
      }
    } catch (e) {

    }

    if (this.cronometroInfo.tiempo == 0) {
      clearInterval(this.cronometro);
    }

  }
  //CRONOMETRO


}
