import { ExamenPersonalizado } from './../models/examen-personalizado';
import { ExamenDiagnostico } from './../models/examen-diagnostico';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Usuario } from '../models/usuario';
import { GLOBAL } from './global';
import { ExamenSimulacro } from '../models/examen-simulacro';
import { RUTAS } from './enums/rutas.enum';


@Injectable()
export class ServicioExamen {
  public url: string;

  constructor(
    public _http: HttpClient
  ) {
    this.url = GLOBAL.url;
  }

  generarExamenSimulacro(token: string, materiasA: string[]): Observable<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', token);
    let materias = JSON.stringify(materiasA);

    return this._http.post(this.url + RUTAS.EXAMEN_SIMULACRO + 'generar-examen', materias, { headers: headers });
  }

  generarExamenPersonalizado(token: string, temas: any, numPreguntas: any): Observable<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', token);
    let params = {
      temas,
      cantidad: numPreguntas
    };
    let body = JSON.stringify(params);

    return this._http.post(this.url + RUTAS.EXAMEN_PERSONALIZADO + 'generar-examen-personalizado', body, { headers: headers });
  }

  generarExamenMiniSimulacro(token: string, materiasA: string[]): Observable<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', token);
    let materias = JSON.stringify(materiasA);

    return this._http.post(this.url + RUTAS.EXAMEN_DIAGNOSTICO + 'generar-examen-diagnostico', materias, { headers: headers });
  }

  generarExamenPredeterminado(): Observable<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this._http.get(this.url + RUTAS.EXAMEN_DIAGNOSTICO + 'generar-examen-predeterminado', { headers: headers });
  }

  finalizarExamenSimulacro(data: ExamenSimulacro, token: string): Observable<any> {
    var datos = JSON.stringify(data);
    let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', token);

    return this._http.post(this.url + RUTAS.EXAMEN_SIMULACRO + 'finalizar-examen-simulacro', datos, { headers: headers });
  }

  finalizarExamenDiagnostico(data: ExamenDiagnostico, token: string): Observable<any> {
    var datos = JSON.stringify(data);
    let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', token);

    return this._http.post(this.url + RUTAS.EXAMEN_DIAGNOSTICO + 'finalizar-examen-diagnostico', datos, { headers: headers });
  }

  finalizarExamenPersonalizado(data: ExamenPersonalizado, token: string): Observable<any> {
    var datos = JSON.stringify(data);
    let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', token);

    return this._http.post(this.url + RUTAS.EXAMEN_PERSONALIZADO + 'finalizar-examen-personalizado', datos, { headers: headers });
  }

  obtenerCalificacion(token: string): Observable<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', token);;

    return this._http.get(this.url + RUTAS.EXAMEN_SIMULACRO + 'obtener-calif', { headers: headers });
  }

  revisarCuentaExamenSimulacro(token: string): Observable<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', token);;

    return this._http.get(this.url + RUTAS.PAGO + 'revisar-pago-examen', { headers: headers });
  }

  revisarCuentaExmaneDiagnostico(token: string): Observable<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', token);;

    return this._http.get(this.url + RUTAS.EXAMEN_DIAGNOSTICO + 'revisar-cuenta-examen', { headers: headers });
  }

}
