<div class="container-fluid p-0">
  <!-- TOP TEST -->
  <div class="row my-3 container-desktop">
    <div class="col-md-6 centrar-contenido">
      <img src="../../../../assets/images/landing-pages/recon-campaign/doctor-image.png" alt="" class="img-fluid">
    </div>
    <div class="col-md-6 centrar-contenido">
      <div>
        <h1>¿Quieres estudiar medicina?</h1>
        <p>Descubre si pasarías tu examen de admisión en solo 30 preguntas.</p>
        <div class="text-center">
          <button class="btn btn-medprep letra-celular shadow" (click)="generarExamenPredeterminado()">Hacer
            Test</button>
        </div>
      </div>
    </div>
  </div>
  <!-- END OF TOP TEST -->

  <!-- WHAT IS -->
  <div class="fondo pb-5">
    <div class="container-desktop px-3 mt-5" id="2">
      <h2>¿Qué es MedPrep?</h2>
      <!-- REAL CLASSES -->
      <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
        <ol class="carousel-indicators">
          <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
          <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
          <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
          <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
          <li data-target="#carouselExampleIndicators" data-slide-to="4"></li>
        </ol>
        <div class="carousel-inner">
          <div class="carousel-item active">
            <img src="../../../../assets/images/landing-pages/recon-campaign/imagenPromo5.png" class="d-block w-100"
              alt="...">
          </div>
          <div class="carousel-item">
            <img src="../../../../assets/images/landing-pages/recon-campaign/imagenPromo4.png" class="d-block w-100"
              alt="...">
          </div>
          <div class="carousel-item">
            <img src="../../../../assets/images/landing-pages/recon-campaign/imagenPromo3.png" class="d-block w-100"
              alt="...">
          </div>
          <div class="carousel-item">
            <img src="../../../../assets/images/landing-pages/recon-campaign/imagenPromo2.png" class="d-block w-100"
              alt="...">
          </div>
          <div class="carousel-item">
            <img src="../../../../assets/images/landing-pages/recon-campaign/imagenPromo1.png" class="d-block w-100"
              alt="...">
          </div>
        </div>
      </div>
      <!-- END OF REAL CLASSES -->
      <p class="mt-2">Un curso creado por médicos y estudiantes de medicina con los mejores puntajes en el examen de
        admisión.</p>
      <div class="text-center">
        <button class="btn btn-medprep letra-celular boton-blanco shadow" data-target="#modalInteresados" data-toggle="modal">Apartar gratis</button>
        <button class="btn btn-medprep letra-celular boton-blanco shadow ml-2" (click)="comprar()">Comprar</button>
      </div>
    </div>
    <!-- END OF WHAT IS -->

    <!-- CONTENT -->
    <div class="container-desktop px-3">
      <h2 class="mt-5">Conoce el contenido del curso</h2>
      <div class="accordion" *ngIf="materiasList.length > 0" id="accordionExample">
        <div class="card">
          <div class="card-header" id="headingOne">
            <h2 class="mb-0">
              <button class="btn btn-block text-left" type="button" data-toggle="collapse" data-target="#collapseOne"
                aria-expanded="true" aria-controls="collapseOne" (click)="obtenerTemas(materiasList[0]._id)">
                <i class="fas fa-caret-right mr-2"></i>{{materiasList[0].nombre}}
              </button>
            </h2>
          </div>
          <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
            <div class="card-body">
              <ol if.bind="temasList.length > 0">
                <li *ngFor="let tema of temasList">
                  <a [routerLink]="['/summary', tema._id]" class="tema-font"
                    *ngIf="tema.curso < 2;else elseBlock">{{tema.nombre}}</a>
                  <ng-template #elseBlock>
                    <span>{{tema.nombre}}</span>
                  </ng-template>
                </li>
              </ol>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header" id="headingTwo">
            <h2 class="mb-0">
              <button class="btn btn-block text-left collapsed" type="button" data-toggle="collapse"
                data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo"
                (click)="obtenerTemas(materiasList[1]._id)">
                <i class="fas fa-caret-right mr-2"></i>{{materiasList[1].nombre}}
              </button>
            </h2>
          </div>
          <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
            <div class="card-body">
              <ol if.bind="temasList.length > 0">
                <li *ngFor="let tema of temasList">
                  <a [routerLink]="['/summary', tema._id]" class="tema-font"
                    *ngIf="tema.curso < 2;else elseBlock">{{tema.nombre}}</a>
                  <ng-template #elseBlock>
                    <span>{{tema.nombre}}</span>
                  </ng-template>
                </li>
              </ol>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header" id="headingThree">
            <h2 class="mb-0">
              <button class="btn btn-block text-left collapsed" type="button" data-toggle="collapse"
                data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree"
                (click)="obtenerTemas(materiasList[2]._id)">
                <i class="fas fa-caret-right mr-2"></i>{{materiasList[2].nombre}}
              </button>
            </h2>
          </div>
          <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
            <div class="card-body">
              <ol if.bind="temasList.length > 0">
                <li *ngFor="let tema of temasList">
                  <a [routerLink]="['/summary', tema._id]" class="tema-font"
                    *ngIf="tema.curso < 2;else elseBlock">{{tema.nombre}}</a>
                  <ng-template #elseBlock>
                    <span>{{tema.nombre}}</span>
                  </ng-template>
                </li>
              </ol>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header" id="headingThree">
            <h2 class="mb-0">
              <button class="btn btn-block text-left collapsed" type="button" data-toggle="collapse"
                data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour"
                (click)="obtenerTemas(materiasList[3]._id)">
                <i class="fas fa-caret-right mr-2"></i>{{materiasList[3].nombre}}
              </button>
            </h2>
          </div>
          <div id="collapseFour" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
            <div class="card-body">
              <ol if.bind="temasList.length > 0">
                <li *ngFor="let tema of temasList">
                  <a [routerLink]="['/summary', tema._id]" class="tema-font"
                    *ngIf="tema.curso < 2;else elseBlock">{{tema.nombre}}</a>
                  <ng-template #elseBlock>
                    <span>{{tema.nombre}}</span>
                  </ng-template>
                </li>
              </ol>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header" id="headingThree">
            <h2 class="mb-0">
              <button class="btn btn-block text-left collapsed" type="button" data-toggle="collapse"
                data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive"
                (click)="obtenerTemas(materiasList[4]._id)">
                <i class="fas fa-caret-right mr-2"></i>{{materiasList[4].nombre}}
              </button>
            </h2>
          </div>
          <div id="collapseFive" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
            <div class="card-body">
              <ol if.bind="temasList.length > 0">
                <li *ngFor="let tema of temasList">
                  <a [routerLink]="['/summary', tema._id]" class="tema-font"
                    *ngIf="tema.curso < 2;else elseBlock">{{tema.nombre}}</a>
                  <ng-template #elseBlock>
                    <span>{{tema.nombre}}</span>
                  </ng-template>
                </li>
              </ol>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header" id="headingThree">
            <h2 class="mb-0">
              <button class="btn btn-block text-left collapsed" type="button" data-toggle="collapse"
                data-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix"
                (click)="obtenerTemas(materiasList[5]._id)">
                <i class="fas fa-caret-right mr-2"></i>{{materiasList[5].nombre}}
              </button>
            </h2>
          </div>
          <div id="collapseSix" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
            <div class="card-body">
              <ol if.bind="temasList.length > 0">
                <li *ngFor="let tema of temasList">
                  <a [routerLink]="['/summary', tema._id]" class="tema-font"
                    *ngIf="tema.curso < 2;else elseBlock">{{tema.nombre}}</a>
                  <ng-template #elseBlock>
                    <span>{{tema.nombre}}</span>
                  </ng-template>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="text-center mt-3">
      <button data-toggle="modal" data-target="#modalGuias" class="btn btn-medprep letra-celular boton-blanco shadow"
        (click)="obtenerGuia()">Descargar la
        guía</button>
    </div>
    <!-- END OF CONTENT -->
  </div>

  <!-- STATISTICS -->
  <div class="row statistics p-4 mb-5 mx-0 shadow">
    <div class="col-3 centrar-contenido">
      <h1 class="mb-0">80%</h1>
    </div>
    <div class="col-9 centrar-contenido">
      <p class="mb-0">De nuestros estudiantes pasan su examen</p>
    </div>
  </div>
  <!-- END OF STADISTICS -->

  <!-- QUE OBTENGO -->
  <div>
    <h2 class="px-3">¿Qué obtengo si compro el curso?</h2>
    <div class="text-center">
      <img src="../../../../assets/images/landing-pages/recon-campaign/tarjetaClases.svg" class="img-fluid w-100" alt="">
      <img src="../../../../assets/images/landing-pages/recon-campaign/tarjetaPlataforma.svg" class="img-fluid w-100" alt="">
      <img src="../../../../assets/images/landing-pages/recon-campaign/tarjeta1.svg" class="img-fluid w-50" alt="">
      <img src="../../../../assets/images/landing-pages/recon-campaign/tarjeta2.svg" class="img-fluid w-50" alt="">
      <img src="../../../../assets/images/landing-pages/recon-campaign/tarjeta3.svg" class="img-fluid w-50" alt="">
      <img src="../../../../assets/images/landing-pages/recon-campaign/tarjeta4.svg" class="img-fluid w-50" alt="">
      <img src="../../../../assets/images/landing-pages/recon-campaign/tarjeta5.svg" class="img-fluid w-50" alt="">
      <img src="../../../../assets/images/landing-pages/recon-campaign/tarjeta6.svg" class="img-fluid w-50" alt="">
      <img src="../../../../assets/images/landing-pages/recon-campaign/tarjeta7.svg" class="img-fluid w-50" alt="">
      <img src="../../../../assets/images/landing-pages/recon-campaign/tarjeta8.svg" class="img-fluid w-50" alt="">
    </div>
    <div class="text-center">
      <button class="btn btn-medprep btn-colores-invertidos letra-celular shadow" (click)="comprar()">Comprar
        ahora</button>
    </div>
  </div>
  <!-- FIN DE QUE OBTENGO  -->

  <!-- OPINIONS -->
  <div class="fondo">
    <div class="container-desktop px-3 mt-5">
      <h2>Escucha las opiniones de nuestros estudiantes</h2>
      <div class="text-center">
        <youtube-player [videoId]="getVideoId('https://www.youtube.com/watch?v=ZsoCSTQEtig')" suggestedQuality="highres" [height]="250"
          [width]="this.vw"></youtube-player>
      </div>
    </div>
  </div>
  <!-- END OF STADISTICS -->

    <div class="carusel-background-uno container-desktop" style="margin-top: 32px;">
      <owl-carousel-o [options]="customOptions">
        <ng-template carouselSlide>
          <div class="px-3 pb-3">
            <div class="card w-100 shadow mx-auto carousel-card">
              <div class="card-body text-center" style="padding-top: 24px;">
                <img src="../../../../assets/images/main-page/reseñas/Santiago.jpeg" class="resena-imagen mx-auto"
                  alt="">
                <h5 style="margin-top: 6px;" class="fuente-resena-1 mb-0">Santiago</h5>
                <p class="mb-2  fuente-resena-2">Medicina UAC</p>
                <img src="../../../../assets/images/landing-pages/recon-campaign/star5.png"
                  class="img-fluid w-25 mx-auto" alt="">
                <p class="pt-3 fuente-resena-3">Yo creo que presentar exámenes simulacro es lo que más me ayudó a
                  acostumbrarme al tipo de preguntas del exámen.</p>
              </div>
            </div>
          </div>
        </ng-template>
        <ng-template carouselSlide class="centrar-resena">
          <div class="p-3">
            <div class="card w-100 shadow mx-auto carousel-card">
              <div class="card-body text-center">
                <img src="../../../../assets/images/main-page/reseñas/Samantha.jpeg" class="resena-imagen mx-auto"
                  alt="">
                <h5 style="margin-top: 6px;" class="fuente-resena-1 mb-0">Samantha</h5>
                <p class="mb-2  fuente-resena-2">Medicina UAC</p>
                <img src="../../../../assets/images/landing-pages/recon-campaign/star5.png"
                  class="img-fluid w-25 mx-auto" alt="">
                <p class="pt-3 fuente-resena-3">La verdad estuvo increíble, aprendí muy bien y los profes fueron muy
                  profesionales y agradables. También la página en línea es de gran ayuda para repasar y estudiar.</p>
              </div>
            </div>
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="p-3">
            <div class="card w-100 shadow mx-auto carousel-card">
              <div class="card-body text-center">
                <img src="../../../../assets/images/main-page/reseñas/Ana Rodriguez.jpeg" class="resena-imagen mx-auto"
                  alt="">
                <h5 style="margin-top: 6px;" class="fuente-resena-1 mb-0">Ana</h5>
                <p class="mb-2  fuente-resena-2">Medicina UAC</p>
                <img src="../../../../assets/images/landing-pages/recon-campaign/star5.png"
                  class="img-fluid w-25 mx-auto" alt="">
                <p class="pt-3 fuente-resena-3">Ha sido de las mejores cosas que he vivido. Los profes son muy buenos,
                  "capturan" tu atención y caen súper bien. La información es breve y concisa, justo lo necesario.</p>
              </div>
            </div>
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="p-3">
            <div class="card w-100 shadow mx-auto carousel-card">
              <div class="card-body text-center">
                <img src="../../../../assets/images/main-page/reseñas/Evelyn.jpeg" class="resena-imagen mx-auto" alt="">
                <h5 style="margin-top: 6px;" class="fuente-resena-1 mb-0">Evelyn</h5>
                <p class="mb-2  fuente-resena-2">Medicina UANL</p>
                <img src="../../../../assets/images/landing-pages/recon-campaign/star5.png"
                  class="img-fluid w-25 mx-auto" alt="">
                <p class="pt-3 fuente-resena-3">Es un curso muy completo, innovador e intensivo. Su plataforma, si la
                  sabes aprovechar es una joyita.</p>
              </div>
            </div>
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="p-3">
            <div class="card w-100 shadow mx-auto carousel-card">
              <div class="card-body text-center">
                <img src="../../../../assets/images/main-page/reseñas/Daniela.jpeg" class="resena-imagen mx-auto"
                  alt="">
                <h5 style="margin-top: 6px;" class="fuente-resena-1 mb-0">Daniela</h5>
                <p class="mb-2  fuente-resena-2">Medicina UJED</p>
                <img src="../../../../assets/images/landing-pages/recon-campaign/star5.png"
                  class="img-fluid w-25 mx-auto" alt="">
                <p class="pt-3 fuente-resena-3">Un curso muy completo, donde se vieron temas muy importantes y se trató
                  de
                  evitar información de relleno. Dan mucha atención a los alumnos. Los profesores son los mejores.</p>
              </div>
            </div>
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="p-3">
            <div class="card w-100 shadow mx-auto carousel-card">
              <div class="card-body text-center">
                <img src="../../../../assets/images/main-page/reseñas/Francisco.jpeg" class="resena-imagen mx-auto"
                  alt="">
                <h5 style="margin-top: 6px;" class="fuente-resena-1 mb-0">Francisco</h5>
                <p class="mb-2  fuente-resena-2">Medicina UAZ</p>
                <img src="../../../../assets/images/landing-pages/recon-campaign/star5.png"
                  class="img-fluid w-25 mx-auto" alt="">
                <p class="pt-3 fuente-resena-3">Los profesores son muy buena onda. Al principio no sabía muchas cosas,
                  pero repasando los resúmenes te das muy buena idea de todo y los profes complementan muy bien.</p>
              </div>
            </div>
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="p-3">
            <div class="card w-100 shadow mx-auto carousel-card">
              <div class="card-body text-center">
                <img src="../../../../assets/images/main-page/reseñas/Camila.jpeg" class="resena-imagen mx-auto" alt="">
                <h5 style="margin-top: 6px;" class="fuente-resena-1 mb-0">Camila</h5>
                <p class="mb-2  fuente-resena-2">Medicina UASLP</p>
                <img src="../../../../assets/images/landing-pages/recon-campaign/star5.png"
                  class="img-fluid w-25 mx-auto" alt="">
                <p class="pt-3 fuente-resena-3">Me sentí muy segura en el examen de admisión porque sentí que era como
                  presentar otro examen simulacro. Las clases con los profes también estaban muy cool.</p>
              </div>
            </div>
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="p-3">
            <div class="card w-100 shadow mx-auto carousel-card">
              <div class="card-body text-center">
                <img src="../../../../assets/images/main-page/reseñas/Angie.jpeg" class="resena-imagen mx-auto" alt="">
                <h5 style="margin-top: 6px;" class="fuente-resena-1 mb-0">Angie</h5>
                <p class="mb-2  fuente-resena-2">Medicina UAD</p>
                <img src="../../../../assets/images/landing-pages/recon-campaign/star5.png"
                  class="img-fluid w-25 mx-auto" alt="">
                <p class="pt-3 fuente-resena-3">Fue una muy buena elección. La relación que se podía tener con los
                  maestros era muy buena.</p>
              </div>
            </div>
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="p-3">
            <div class="card w-100 shadow mx-auto carousel-card">
              <div class="card-body text-center">
                <img src="../../../../assets/images/main-page/reseñas/Andres.jpeg" class="resena-imagen mx-auto" alt="">
                <h5 style="margin-top: 6px;" class="fuente-resena-1 mb-0">Andres</h5>
                <p class="mb-2  fuente-resena-2">Medicina UAC</p>
                <img src="../../../../assets/images/landing-pages/recon-campaign/star5.png"
                  class="img-fluid w-25 mx-auto" alt="">
                <p class="pt-3 fuente-resena-3">Repasar de último momento con las flashcards si tira un parote.</p>
              </div>
            </div>
          </div>
        </ng-template>
      </owl-carousel-o>
    </div>
    <!-- END OF OPINIONS -->
    <!-- REASONS WHY -->
    <div class="row my-5 px-3 container-desktop">
      <h2 class="px-3">¿Por qué la mayoría de la gente no pasa el examen?</h2>
      <div class="row row-cols-1 row-cols-md-2">
        <div class="col mb-4">
          <div class="card shadow rounded">
            <div class="card-body">
              <h4 class="medprep-font-color">1.</h4>
              <h4 class="card-title cards-title-font-size">Comienzan tarde</h4>
              <p class="card-text cards-text-font-size">La mayoría de los estudiantes <span class="font-weight-bold">aceptados</span> en la
                carrera estudiaron en promedio durante
                1-3 meses antes de presentar su examen.</p>
            </div>
          </div>
        </div>
        <div class="col mb-4">
          <div class="card shadow rounded">
            <div class="card-body">
              <h4 class="medprep-font-color">2.</h4>
              <h4 class="card-title cards-title-font-size">No saben por donde empezar</h4>
              <p class="card-text cards-text-font-size">Al comenzar a estudiar <span class="font-weight-bold">no saben que temas estudiar</span>, ni como hacerlo de manera efectiva.
              </p>
            </div>
          </div>
        </div>
        <div class="col mb-4">
          <div class="card shadow rounded">
            <div class="card-body">
              <h4 class="medprep-font-color">3.</h4>
              <h4 class="card-title cards-title-font-size">No conocen el examen</h4>
              <p class="card-text cards-text-font-size"><span class="font-weight-bold">No haber practicado</span> con exámenes parecidos a tu examen de admisión te pone en
                desventaja.
              </p>
            </div>
          </div>
        </div>
        <div class="col mb-4">
          <div class="card shadow rounded">
            <div class="card-body">
              <h4 class="medprep-font-color">4.</h4>
              <h4 class="card-title cards-title-font-size">No recuerdan la competencia</h4>
              <p class="card-text cards-text-font-size">Solo el 5% de los aspirantes a medicina son aceptados, recuerda que compites contra los mejores de todas las escuelas y solo los más preparados triunfarán.</p>
            </div>
          </div>
        </div>
      </div>

      <!-- END OF REASONS WHY -->
    </div>
  </div>



  <!-- PROMO -->
  <div class="fondo row mt-3 container-desktop pb-5">
    <div class="col-md-6 centrar-contenido">
      <img src="../../../../assets/images/landing-pages/recon-campaign/doctora2.png" alt="" class="img-fluid">
    </div>
    <div class="col-md-6 centrar-contenido">
      <div>
        <h1 class="titulo-promo w-100 text-center">Consíguelo antes del 31 de enero y obtén <span
            class="porcentaje">30%</span> de descuento</h1>
        <p class="code-font text-center">Código: MEDPREPENERO</p>
        <div class="text-center">
          <button class="btn btn-medprep letra-celular boton-blanco shadow mt-0" (click)="comprar()">Comprar ahora</button>
        </div>
        <p class="letra-promo text-center">Compra 100% segura y protegida por Stripe.</p>
      </div>
    </div>
    <div class="centrar-contenido mx-auto">
      <img src="../../../../assets/images/landing-pages/recon-campaign/iconosPago.svg" alt="" class="img-fluid">
    </div>
  </div>
  <!-- END OF PROMO -->

<!-- Modal Guias-->
<div class="modal fade" id="modalGuias" tabindex="-1" aria-labelledby="modalGuiasLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="modalGuiasLabel">Descarga la guia que necesites</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <ol>
          <li> <a class="d-block" href="../../../../assets/guias/Guía EXANI II.pdf"
              download="Guia Exani II 2023.pdf">Guia EXANI II</a>
          </li>
          <li> <a class="d-block" href="../../../../assets/guias/Modulos especificos del EXANI II.pdf"
              download="Modulos especificos del EXANI II.pdf">Modulos especificos del EXANI II</a>
          </li>
          <li> <a class="d-block" href="../../../../assets/guias/Guía College Board.pdf"
              download="Guía College Board.pdf">Guía College Board</a>
          </li>
          <li> <a class="d-block" href="../../../../assets/guias/Guía exhcoba.pdf" download="Guía exhcoba.pdf">Guía
              exhcoba</a>
          </li>
          <li> <a class="d-block" href="../../../../assets/guias/Guía exbach CS 2022.PDF" download="Guía exbach CS 2022.pdf">Guía exbach CS 2022</a>
          </li>
        </ol>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
      </div>
    </div>
  </div>
</div>

<!-- Modal Interesados-->
<div class="modal fade" id="modalInteresados" tabindex="-1" aria-labelledby="modalInteresadosLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="modalGuiasLabel">Déjanos tus datos para apartar</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="form-group">
          <label for="nombre">Nombre</label>
          <input type="text" name="nombre" #nombre="ngModel" [(ngModel)]="interesado.nombre" placeholder="Nombre" class="form-control" id="nombre">
        </div>
        <div class="form-group">
          <label for="correo">Correo</label>
          <input type="email" class="form-control" name="correo" #correo="ngModel" [(ngModel)]="interesado.correo" placeholder="Correo" id="correo">
        </div>
        <div class="form-group">
          <label for="telefono">Teléfono</label>
          <input type="tel" class="form-control" name="telefono" #telefono="ngModel" [(ngModel)]="interesado.telefono" id="telefono" placeholder="Teléfono">
        </div>
      </div>
      <div class="modal-footer text-center">
        <button class="btn btn-medprep" (click)="guardarDatosInteresado()" data-dismiss="modal">Apartar</button>
      </div>
    </div>
  </div>
</div>
