import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ServicioResumen } from 'src/app/services/resumen.service';
import { ServicioTemas } from 'src/app/services/temas.service';
import { ServicioUsuario } from 'src/app/services/usuario.service';

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.sass', './summary.component.css']
})
export class SummaryComponent implements OnInit {

  public resumen;
  private token;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _servicioResumen: ServicioResumen,
    private _servicioTema: ServicioTemas,
    private _servicioUsuario: ServicioUsuario
  ) {
    this.token = _servicioUsuario.getToken();
  }

  ngOnInit(): void {
    this._route.params.subscribe(params => {
      let id = params['id'];
      this.obtenerResumen(id);
      this.guardarProgreso(id);
    }, error => {
      if (error) {
        this._router.navigate(['/']);
      }
    });
  }

  guardarProgreso(idActividad) {
    this._servicioTema.guardarProgreso(idActividad, this.token).subscribe(response => {
    }, error => {
      if (error) {
        console.log(error);
      }
    });
  }

  obtenerResumen(id) {
    this._servicioResumen.obtenerResumen(id).subscribe(response => {
      this.resumen = response.resumen[0];
    }, error => {
      if (error) {
        this._router.navigate(['/']);
      }
    });
  }

}
