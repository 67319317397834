<div class="">
    <div class="container-faq mb-5">
        <!--         <div class="container-image-faq"></div> -->
        <div class="mx-auto">
            <div class="" style="margin-top: 200px;">
                <h1 class="m-0 mb-3 bold-title main-font-title w-100 text-center">¿Tienes preguntas?</h1>
                <div class="my-2 main-font-body text-center mx-auto info-text">
                    <p>Tenemos una sección con las preguntas más frecuentes, si tu pregunta no viene aquí puedes <a href="mailto:contacto@medprep.com.mx">mandarnos un correo electronico</a> y resolveremos tus dudas.</p>
                </div>
                <div class="container">
                    <div class="accordion" id="accordionExample">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="card my-2 shadow" style="border-radius: 20px;">
                                    <div class="card-header " style="border-radius: 20px;" id="headingOne">
                                        <h2 class="mb-0">
                                            <button class="btn btn-link btn-block text-left p-0 collapsed" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                ¿Para cuáles univesidades es util este curso?
                                      </button>
                                        </h2>
                                    </div>

                                    <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
                                        <div class="card-body main-font-body">
                                            Nuestro contenido es una compilación de distintas guías de admisión que se aplican a nivel nacional en diferentes universidades, entre las que se encuentran las siguientes: <a href="" data-toggle="modal" data-target="#universidades">ver universidades</a>.
                                            <p>
                                                ¿No encuentras tu universidad aquí? <a href="https://wa.me/message/MKMYS7XRPUCLA1">Mándanos un mensaje</a>, preséntanos tu temario de estudio y te diremos si este curso puede ser útil para ti.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card my-2 shadow" style="border-radius: 20px;">
                                    <div class="card-header" style="border-radius: 20px;" id="headingTwo">
                                        <h2 class="mb-0">
                                            <button class="btn btn-link btn-block text-left collapsed p-0" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                ¿Para qué carreras puedo utilizar MedPrep?
                                      </button>
                                        </h2>
                                    </div>
                                    <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                                        <div class="card-body main-font-body">
                                            MedPrep está dirigido hacia alumnos que quieran pasar su examen de admisión en las carreras de:
                                            <ul>
                                                <li>Medicina</li>
                                                <li>Odontología</li>
                                                <li>Nutrición</li>
                                                <li>Enfermería</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="card my-2 shadow" style="border-radius: 20px;">
                                    <div class="card-header" style="border-radius: 20px;" id="headingThree">
                                        <h2 class="mb-0">
                                            <button class="btn btn-link btn-block text-left collapsed p-0" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                ¿Cuándo inicia el curso?
                                      </button>
                                        </h2>
                                    </div>
                                    <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                        <div class="card-body main-font-body">
                                            Nuestro próximo curso MedPrep Pro inicia el 1 de abril de 2023. ¿No puedes esperar? puedes adquirir nuestro curso MedPrep Plus cuando sea que a ti más se te acomode o bien puedes adquirir algún curso de nuestras colaboraciónes.
                                            <a href="" [routerLink]="['/information/prices']" routerLinkActive="router-link-active">Más información</a>
                                        </div>
                                    </div>
                                </div>
                                <!--                                 <div class="card my-2 shadow" style="border-radius: 20px;">
                                    <div class="card-header" style="border-radius: 20px;" id="headingFour">
                                        <h2 class="mb-0">
                                            <button class="btn btn-link btn-block text-left collapsed p-0" type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseThree">
                                        Collapsible Group Item #4
                                      </button>
                                        </h2>
                                    </div>
                                    <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordionExample">
                                        <div class="card-body">
                                            And lastly, the placeholder content for the third and final accordion panel. This panel is hidden by default.
                                        </div>
                                    </div>
                                </div>
                                <div class="card my-2 shadow" style="border-radius: 20px;">
                                    <div class="card-header" style="border-radius: 20px;" id="headingFive">
                                        <h2 class="mb-0">
                                            <button class="btn btn-link btn-block text-left collapsed p-0" type="button" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseThree">
                                        Collapsible Group Item #5
                                      </button>
                                        </h2>
                                    </div>
                                    <div id="collapseFive" class="collapse" aria-labelledby="headingFive" data-parent="#accordionExample">
                                        <div class="card-body">
                                            And lastly, the placeholder content for the third and final accordion panel. This panel is hidden by default.
                                        </div>
                                    </div>
                                </div> -->
                            </div>
                            <div class="col-md-6">
                                <div class="card my-2 shadow" style="border-radius: 20px;">
                                    <div class="card-header" style="border-radius: 20px;" id="headingSix">
                                        <h2 class="mb-0">
                                            <button class="btn btn-link btn-block text-left p-0 collapsed" type="button" data-toggle="collapse" data-target="#collapseSix" aria-expanded="true" aria-controls="collapseOne">
                                                ¿Qué precio tiene MedPrep?
                                      </button>
                                        </h2>
                                    </div>

                                    <div id="collapseSix" class="collapse" aria-labelledby="headingSix" data-parent="#accordionExample">
                                        <div class="card-body main-font-body">
                                            ¡Puedes probar MedPrep completamente gratis! ¿Quieres adquirir el curso completo? Aquí puedes ver nuestros precios: <a href="" [routerLink]="['/information/prices']" routerLinkActive="router-link-active">ver precios.</a>
                                        </div>
                                    </div>
                                </div>
                                <div class="card my-2 shadow" style="border-radius: 20px;">
                                    <div class="card-header" style="border-radius: 20px;" id="headingSeven">
                                        <h2 class="mb-0">
                                            <button class="btn btn-link btn-block text-left collapsed p-0" type="button" data-toggle="collapse" data-target="#collapseSeven" aria-expanded="false" aria-controls="collapseTwo">
                                                ¿En qué horarios se dan las clases?
                                      </button>
                                        </h2>
                                    </div>
                                    <div id="collapseSeven" class="collapse" aria-labelledby="headingSeven" data-parent="#accordionExample">
                                        <div class="card-body main-font-body">
                                            Las clases se impartirán todos los días en horarior de 9:00 - 15:00 hrs. ¿No puedes asisitir a alguna clase? No te preocupes, nuestras clases quedan grabadas para que las veas cuantas veces quieras.
                                        </div>
                                    </div>
                                </div>
                                <div class="card my-2 shadow" style="border-radius: 20px;">
                                    <div class="card-header" style="border-radius: 20px;" id="headingEight">
                                        <h2 class="mb-0">
                                            <button class="btn btn-link btn-block text-left collapsed p-0" type="button" data-toggle="collapse" data-target="#collapseEight" aria-expanded="false" aria-controls="collapseThree">
                                                ¿Qué incluye el curso MedPrep?
                                      </button>
                                        </h2>
                                    </div>
                                    <div id="collapseEight" class="collapse" aria-labelledby="headingEight" data-parent="#accordionExample">
                                        <div class="card-body main-font-body">
                                            MedPrep es un curso premédico intensivo con su propio sitio web interactivo que incluye un montón de funcionalidades increíbles que te ayudarán a pasar tu examen de admisión. Aquí puedes ver lo qué incluye cada uno de nuestros cursos:
                                            <a href="" [routerLink]="['/payment/prices']" routerLinkActive="router-link-active">ver más.</a>
                                        </div>
                                    </div>
                                </div>
                                <!--                                 <div class="card my-2 shadow" style="border-radius: 20px;">
                                    <div class="card-header" style="border-radius: 20px;" id="headingNine">
                                        <h2 class="mb-0">
                                            <button class="btn btn-link btn-block text-left collapsed p-0" type="button" data-toggle="collapse" data-target="#collapseNine" aria-expanded="false" aria-controls="collapseThree">
                                        Collapsible Group Item #9
                                      </button>
                                        </h2>
                                    </div>
                                    <div id="collapseNine" class="collapse" aria-labelledby="headingNine" data-parent="#accordionExample">
                                        <div class="card-body">
                                            And lastly, the placeholder content for the third and final accordion panel. This panel is hidden by default.
                                        </div>
                                    </div>
                                </div>
                                <div class="card my-2 shadow" style="border-radius: 20px;">
                                    <div class="card-header" style="border-radius: 20px;" id="headingTen">
                                        <h2 class="mb-0">
                                            <button class="btn btn-link btn-block text-left collapsed p-0" type="button" data-toggle="collapse" data-target="#collapseTen" aria-expanded="false" aria-controls="collapseThree">
                                        Collapsible Group Item #10
                                      </button>
                                        </h2>
                                    </div>
                                    <div id="collapseTen" class="collapse" aria-labelledby="headingTen" data-parent="#accordionExample">
                                        <div class="card-body">
                                            And lastly, the placeholder content for the third and final accordion panel. This panel is hidden by default.
                                        </div>
                                    </div>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>


<!-- <a href="" data-toggle="modal" data-target="#universidades">ver universidades</a>  -->

<!-- MODAL UNIVERSIDADES -->
<div class="modal fade" id="universidades" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title font-text-medprep" id="exampleModalLabel">Universiades</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
            </div>
            <div class="modal-body">
                <ul>
                    <li>
                        Universidad Autónoma de Coahuila (Unidad Torreón)
                    </li>
                    <li>
                        Universidad Autónoma de Coahuila (Unidad Saltillo)
                    </li>
                    <li>
                        Universidad Juárez del Edo. de Durango-Unidad Durango – UJED (Durango)
                    </li>
                    <li>
                        Unversidad Juarez del Edo. de Durango- UJED (Gómez Palacio)
                    </li>
                    <li>
                        Instituto Tecnológico y de Estudios Superiores de Monterrey (Monterrey)
                    </li>
                    <li>
                        Universidad Autónoma de Nuevo León – UANL
                    </li>
                    <li>
                        Universidad Autónoma de Baja California (Tijuana)
                    </li>
                    <li>
                        Universidad Autónoma de Baja California (Ensenada)
                    </li>
                    <li>
                        Universidad Autónoma de Baja California (Mexicali)
                    </li>
                    <li>
                        Universidad Autónoma de Campeche
                    </li>
                    <li>
                        Instituto de Estudios Superiores de Chiapas (Tuxtla Gutiérrez)
                    </li>
                    <li>
                        Instituto de Estudios Superiores de Chiapas (Tapachula)
                    </li>
                    <li>
                        Universidad Autónoma de Chiapas (Tuxtla Gutiérrez)
                    </li>
                    <li>
                        Universidad Autónoma de Chihuahua (Chihuahua)
                    </li>
                    <li>
                        Universidad La Salle
                    </li>
                    <li>
                        Universidad Panamericana
                    </li>
                    <li>
                        Centro Cultural Universitario " Justo Sierra"
                    </li>
                    <li>
                        Universidad de Colima
                    </li>
                    <li>
                        Universidad España de Durango
                    </li>
                    <li>
                        Universidad Anahuac Norte
                    </li>
                    <li>
                        Universidad Quetzalcóatl en Irapuato
                    </li>
                    <li>
                        Universidad de Celaya
                    </li>
                    <li>
                        Universidad Autónoma de Guerrero
                    </li>
                    <li>
                        Universidad Autónoma del Estado de Hidalgo
                    </li>
                    <li>
                        Universidad de Guadalajara (Centro Universitario de la Costa)
                    </li>
                    <li>
                        Universidad de Guadalajara (Centro Universitario del Sur)
                    </li>
                    <li>
                        Universidad de Guadalajara (Centro Universitario de Ciencias de la Salud)
                    </li>
                    <li>
                        Universidad Michoacana de San Nicolás de Hidalgo
                    </li>
                    <li>
                        Universidad Latinoamericana (Cuernavaca)
                    </li>
                    <li>
                        Universidad Autónoma del Estado de Morelos
                    </li>
                    <li>
                        Universidad Autónoma de Nayarit
                    </li>
                    <li>
                        Universidad de Montemorelos
                    </li>
                    <li>
                        Universidad del Sureste
                    </li>
                    <li>
                        Universidad Autónoma "Benito Juárez" de Oaxaca
                    </li>
                    <li>
                        Universidad de las Américas
                    </li>
                    <li>
                        Universidad Autónoma de San Luis Potosí
                    </li>
                    <li>
                        Universidad Autónoma de Sinaloa (Culiacán)
                    </li>
                    <li>
                        Universidad de Sonora (Hermosillo)
                    </li>
                    <li>
                        Universidad del Valle de México (Hermosillo)
                    </li>
                    <li>
                        Instituto de Ciencias y Estudios Superiores de Tamaulipas. A.C. (Tampico)
                    </li>
                    <li>
                        Universidad Autónoma de Tamaulipas (Tampico)
                    </li>
                    <li>
                        Universidad Autónoma de Tlaxcala
                    </li>
                    <li>
                        Universidad Veracruzana (Veracruz)
                    </li>
                    <li>
                        Universidad Autónoma de Yucatán
                    </li>
                    <li>
                        Universidad Autónoma de Zacatecas
                    </li>
                </ul>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
            </div>
        </div>
    </div>
</div>
<!------------------------------------------------------>
