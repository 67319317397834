import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AboutUsComponent } from './about-us/about-us.component';
import { PricesComponent } from './prices/prices.component';
import { ContactComponent } from './contact/contact.component';
import { PrivacyNoticeComponent } from './privacy-notice/privacy-notice.component';
import { FAQComponent } from './faq/faq.component';


const routes: Routes = [{
  path: '',
  children: [
    { path: 'about-us', component: AboutUsComponent },
    { path: 'prices/:id', component: PricesComponent },
    { path: 'prices', component: PricesComponent },
    { path: 'contact', component: ContactComponent },
    { path: 'privacy-notice', component: PrivacyNoticeComponent },
    { path: 'faq', component: FAQComponent },
  ]
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CompanyInfoRoutingModule { }
