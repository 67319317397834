<!------------------------NAVBAR------------------------------->
<app-navbar [ngClass]="{'header': this.identity}"></app-navbar>
<!------------------------------------------------------------->

<!-----------------------CARGA DE COMPONENTES------------------>
<div [ngClass]="{'main-content': this.identity}">
    <router-outlet></router-outlet>
</div>
<!------------------------------------------------------------->

<!-----------------------FOOTER-------------------------------->
<app-footer *ngIf="!this.identity"></app-footer>
<!------------------------------------------------------------->


<!-- MODAL DE SESION -->
<!-- Modal -->
<div class="modal fade" id="modalSesion" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Tu sesión ha expirado</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                Por favor vuelve a iniciar sesión para disfrutar de medprep.
            </div>
            <div class="modal-footer">
                <button (click)="login()" type="button" class="btn btn-medprep" style="width: 40%;">Iniciar sesión</button>
            </div>
        </div>
    </div>
</div>