import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ServicioUsuario } from 'src/app/services/usuario.service';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Router, RouterLink } from '@angular/router';

@Component({
  selector: 'app-main-page',
  templateUrl: './main-page.component.html',
  styleUrls: ['./main-page.component.sass', './main-page.component.css']
})
export class MainPageComponent implements OnInit, AfterViewInit {

  public identity;
  @ViewChild('videoPlayerMain') videoPlayerMain: ElementRef;
  @ViewChild('videoPlayerRes') videoPlayerRes: ElementRef;
  public data;
  public customOptions: OwlOptions = {
    loop: true,
    margin: 32,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: true,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 3
      },
      1350: {
        items: 5
      }
    },
    nav: false
  }

  constructor(
    private _servicioUsuario: ServicioUsuario,
    private _router: Router
    ) {
    this.data = {
      correo: '',
      password: ''
    };
    this.identity = this._servicioUsuario.getIdentity();
  }

  ngAfterViewInit(): void {
    $('#modalMain').on('hidden.bs.modal', (event) => {
      this.videoPlayerMain.nativeElement.pause();
      this.videoPlayerMain.nativeElement.load();
    });
    $('#modalRes').on('hidden.bs.modal', (event) => {
      this.videoPlayerRes.nativeElement.pause();
      this.videoPlayerRes.nativeElement.load();
    });
  }

  ngOnInit(): void {
    this.identity = this._servicioUsuario.getIdentity();
  }

  videoMain() {
    this.videoPlayerMain.nativeElement.play();
  }

  videoRes(){
    this.videoPlayerRes.nativeElement.play();
  }

  sendData() {
    localStorage.setItem('correo', this.data.correo);
    localStorage.setItem('password', this.data.password);

    this._router.navigate(['/auth/registry']);
  }



}
