import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Usuario } from '../models/usuario';
import { GLOBAL } from './global';
import { RUTAS } from './enums/rutas.enum';

@Injectable()
export class ServicioFrases {
    public url: string;

    constructor(
        public _http: HttpClient
    ) {
        this.url = GLOBAL.url + RUTAS.FRASE;
    }

    obtenerFrase(): Observable<any> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');

        return this._http.get(this.url + 'obtener-frase', { headers: headers });
    }

}
