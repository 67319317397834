import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

//COMPONENTES
import { PruebasComponent } from './components/pruebas/pruebas.component';
import { ReconCampaignComponent } from './components/landing-pages/recon-campaign/recon-campaign.component';
import { MainPageComponent } from './components/principal/main-page/main-page.component';
import { SummaryComponent } from './components/content/summary/summary.component';
import { ExamComponent } from './components/content/exam/exam-view/exam.component';


const appRoutes: Routes = [
  { path: '', component: MainPageComponent },
  { path: 'pruebas', component: PruebasComponent },
  { path: 'campaign/:id', component: ReconCampaignComponent },
  { path: 'campaign', component: ReconCampaignComponent },
  { path: 'main-page', component: MainPageComponent },
  { path: 'summary/:id', component: SummaryComponent },
  { path: 'exam', component: ExamComponent },
  {
    path: 'auth', loadChildren: () => import("./components/auth/auth.module").then(m => m.AuthModule)
  },
  {
    path: 'content', loadChildren: () => import("./components/content/content.module").then(m => m.ContentModule)
  },
  {
    path: 'payment', loadChildren: () => import("./components/payment/payment.module").then(m => m.PaymentModule)
  },
  {
    path: 'administration', loadChildren: () => import("./components/administration/administration.module").then(m => m.AdministrationModule)
  },
  {
    path: 'information', loadChildren: () => import("./components/company-info/company-info.module").then(m => m.CompanyInfoModule)
  },
  { path: '**', component: MainPageComponent }
]

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy', scrollPositionRestoration: 'enabled' })
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
