import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Usuario } from '../models/usuario';
import { GLOBAL } from './global';
import { RUTAS } from './enums/rutas.enum';

@Injectable()
export class ServicioFlashcard {
  public url: string;

  constructor(
    public _http: HttpClient
  ) {
    this.url = GLOBAL.url + RUTAS.FLASHCARD;
  }

  crearFlashcard(fd: FormData): Observable<any> {
    return this._http.post(this.url + 'crear-flashcard', fd);
  }

  obtenerFlashcardsPorMateria(materiaId: string): Observable<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this._http.get(this.url + 'obtener-flashcards-por-materia/' + materiaId, { headers: headers });
  }

  obtenerFlashcardsPorTema(temaId: string): Observable<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this._http.get(this.url + 'obtener-flashcards-por-tema/' + temaId, { headers: headers });
  }

  eliminarFlashcard(flascardId: string): Observable<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this._http.delete(this.url + 'borrar-flashcard/' + flascardId, { headers: headers });
  }

}
