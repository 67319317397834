import { filter } from 'rxjs/operators';
import { Materia } from './../models/materia';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Usuario } from '../models/usuario';
import { GLOBAL } from './global';
import { RUTAS } from './enums/rutas.enum';
import { LOCALSTORAGE } from '../services/enums/local-storage-keys.enum';

@Injectable()
export class ServicioMaterias {
  public url: string;

  constructor(
    public _http: HttpClient
  ) {
    this.url = GLOBAL.url + RUTAS.MATERIA;
  }

  obtenerMaterias(): Observable<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this._http.get(this.url + 'obtener-materias', { headers: headers });
  }

  obtenerMateriaDeLocalstoragePorId(id: string): Materia {
    let materias = JSON.parse(localStorage.getItem(LOCALSTORAGE.MATERIAS));

    return materias.find((materia: Materia) => materia._id == id);
  }

  obtenerMateriasDeLocalstorage(): Materia[] {
    return JSON.parse(localStorage.getItem(LOCALSTORAGE.MATERIAS));
  }

}
