import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { ServicioUsuario } from '../usuario.service';

@Injectable()
export class UsuarioGuard implements CanActivate {

    constructor(
        private _servicioUsuario: ServicioUsuario,
        private _router: Router
    ) {

    }

    canActivate() {
        let identity = this._servicioUsuario.getIdentity();

        if (identity && (identity.rol == 'Profesor' || identity.rol == 'Administrador')) {
            return true;
        } else {
            this._router.navigate(['/auth/login']);
            return false;
        }

    }
}