<div class="container main-page-prices">
    <div class="image-precios"></div>
    <div class="text-center my-5">
        <h1 class="font-title-medprep title-principal-mobil">¡Conoce nuestro planes!</h1>
    </div>
    <div class="row centrar-contenido">
        <div class="col-md-4">
            <div class="shadow bg-white p-4" style="min-height: 600px; border-radius: 15px;margin-top: 70px;">
                <h2 class="font-title-medprep text-center">Medprep</h2>
                <h2 class="font-title-medprep text-center">$ 0</h2>
                <div class="mx-auto" style="width: 90%; min-height: 260px;  margin-top: 80px;">
                    <ul>
                        <li class="check-ul">Acceso a algunos temas</li>
                        <li class="check-ul">Realiza un examen simulador por $15</li>
                        <li class="check-ul">Resúmenes (limitados)</li>
                        <li class="check-ul">Tips de estudio (limitados)</li>
                        <li class="check-ul">Flashcards (limitados)</li>
                        <li class="check-ul">Ejercicios (limitados)</li>
                        <li class="check-ul">Estadísticas</li>
                        <li class="cross-ul">Calendario de estudios</li>
                        <li class="cross-ul">Clases en vivo</li>
                        <li class="cross-ul">Acceso a clases grabadas</li>
                    </ul>
                </div>

                <div class="text-center">
                    <button class="btn btn-medprep shadow mx-auto mt-4" [routerLink]="['/auth/registry']" id="boton1" routerLinkActive="router-link-active" style="width: 90%;">Comienza hoy</button>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="shadow bg-white p-4" style="min-height: 600px; border-radius: 15px; margin-top: 70px;">
                <h2 class="font-title-medprep text-center">Plan Plus</h2>
                <h2 class="font-title-medprep text-center">$ 1699</h2>
                <div class="mx-auto" style="width: 90%; min-height: 260px; margin-top: 80px;">
                    <ul>
                        <li class="check-ul">Acceso a todos temas</li>
                        <li class="check-ul">Exámenes simulador</li>
                        <li class="check-ul">Resúmenes</li>
                        <li class="check-ul">Tips de estudio</li>
                        <li class="check-ul">Flashcards</li>
                        <li class="check-ul">Ejercicios</li>
                        <li class="check-ul">Estadísticas</li>
                        <li class="check-ul">Calendario de estudios</li>
                        <li class="cross-ul">Clases en vivo</li>
                        <li class="cross-ul">Acceso a clases grabadas</li>
                    </ul>
                </div>

                <div class="text-center">
                    <button class="boton-comprar shadow mx-auto mb-2" data-toggle="modal" data-target="#medprepPlusModal" id="boton4" routerLinkActive="router-link-active" style="width: 90%;">Más info</button>
                    <button class="btn btn-medprep shadow mx-auto" (click)="comprar('1b')" id="botonPlus" routerLinkActive="router-link-active" style="width: 90%;">Adquirir</button>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div id="pro" class="shadow p-3 margen-mobil" style="border-radius: 18px;background-color: #00AAFF;">
                <div class="text-center centrar-contenido" style="min-height: 70px; color: white;">
                    <h3 class="font-title-medprep-med">El + vendido</h3>
                </div>
                <div class="shadow bg-white p-4" style="min-height: 500px; border-radius: 15px;">
                    <h2 class="font-title-medprep text-center">Plan Pro</h2>
                    <h2 class="font-title-medprep text-center">$ 1799</h2>

                    <div class="row centrar-contenido my-2 fondo-warning p-2" style="border-radius: 15px;">
                        <div class="col-md-3 text-center">
                            <img src="../../../../assets/images/prices/warning.png" class="img-fluid" alt="">
                        </div>
                        <div class="col-md-9 text-center">
                            <p class="py-0 my-0">Inicio de clases</p>
                            <h5 class="py-0 my-0">1 de abril del 2023</h5>
                        </div>
                    </div>

                    <div class="mx-auto" style="width: 90%; min-height: 260px;">
                        <ul>
                            <li class="check-ul">Acceso a todos temas</li>
                            <li class="check-ul">Exámenes simulador</li>
                            <li class="check-ul">Resúmenes</li>
                            <li class="check-ul">Tips de estudio</li>
                            <li class="check-ul">Flashcards</li>
                            <li class="check-ul">Ejercicios</li>
                            <li class="check-ul">Estadísticas</li>
                            <li class="check-ul">Calendario de estudios</li>
                            <li class="check-ul">Clases en vivo</li>
                            <li class="check-ul">Acceso a clases grabadas</li>
                        </ul>
                    </div>

                    <div class="text-center">
                        <button class="boton-comprar shadow mx-auto mb-2" id="boton4" data-toggle="modal" data-target="#medprepProModal" style="width: 90%;">Más info</button>
                        <button class="btn btn-medprep shadow mx-auto" (click)="comprar('2b')" id="botonPro" routerLinkActive="router-link-active" style="width: 90%;">Apartar</button>
                    </div>
                </div>
            </div>

        </div>
    </div>

    <div class="row mt-5 mx-2 shadow bg-white p-4 centrar-contenido" style="border-radius: 15px;">
        <div class="col-md-3 text-center">
            <img src="../../../../assets/images/prices/examen.png" class="img-fluid w-50" alt="">
        </div>
        <div class="col-md-9 text-center">
            <h3>¡Adquiere tu primer examen simulacro por solo $15 pesos!</h3>
            <button class="btn btn-medprep w-50 mx-auto mt-4" [routerLink]="['/payment/checkout', '1ex']" routerLinkActive="router-link-active">Adquirir</button>
        </div>
    </div>
    <div class="row mt-5 mx-2 fondo-warning p-4 centrar-contenido" style="border-radius: 15px;">
        <div class="col-md-3 text-center">
            <img src="../../../../assets/images/prices/warning.png" alt="">
        </div>
        <div class="col-md-9">

            <ul class="my-auto">
                <li>Nuestro plan Pro incluye 2 semanas de clases en vivo que se imparten <b>durante las vacaciones de semana santa.</b> </li>
                <li>Los lugares de este curso son <b>limitados</b>.</li>
                <li>El acceso para <b>todos</b> los alumnos de nuestro Plan Pro se liberará el <b>25 de Marzo</b> independientemente de su día de compra.</li>
                <li>El acceso a la plataforma durará un més a partir del dia 25 de Marzo de 2023.</li>
            </ul>

        </div>
    </div>

    <div class="mb-5 mx-auto" style="width: 75%; margin-top: 100px;">
        <img src="../../../../assets/images/prices/tablaPrecios.png" class="img-fluid" alt="">
    </div>
</div>



<!-- MODAL MEDPREP PLUS-->
<div class="modal fade" id="medprepPlusModal" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-body">
                <h2 class="w-100 text-center font-weight-bold">Medprep <span style="color: rgba(173, 166, 255, 1);">Plus</span></h2>
                <div class="text-center mt-4">
                    <p class="py-0 my-0"> <span class="font-weight-bold"> Duración:</span> 1 mes</p>
                    <p class="py-0 my-0"><span class="font-weight-bold">Incio:</span> El día que lo compras</p>
                    <p class="py-0 my-0"><span class="font-weight-bold">Termina:</span> Un mes a partir del día que lo compras</p>
                </div>

                <h5 class="w-100 text-center mt-3">Contenido</h5>
                <div class="">
                    <ul class="w-50 mx-auto">
                        <li>Material de estudio</li>
                        <li>Exámenes simulacro</li>
                        <li>Calendario de estudios</li>
                        <li>Resúmenes</li>
                        <li>Flashcards</li>
                        <li>Ejercicios</li>
                        <li>Tips</li>
                    </ul>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-medprep w-25 ml-auto" data-dismiss="modal">Cerrar</button>
            </div>
        </div>
    </div>
</div>
<!-- MODAL MEDPREP PLUS-->

<!-- MODAL MEDPREP PRO-->
<div class="modal fade" id="medprepProModal" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-body">
                <h2 class="w-100 text-center font-weight-bold">MedPrep <span style="color: rgba(173, 166, 255, 1);">Pro</span></h2>
                <div class="text-center mt-4">
                    <p class="py-0 my-0"> <span class="font-weight-bold"> Duración:</span> 1 mes</p>
                    <p class="py-0 my-0"><span class="font-weight-bold">Incio:</span> 25 de Marzo del 2023</p>
                    <p class="py-0 my-0"><span class="font-weight-bold">Termina:</span> 1 de Mayo del 2023</p>
                </div>

                <h5 class="w-100 text-center mt-3">Contenido</h5>
                <div class="">
                    <ul class="w-50 mx-auto">
                        <li>Material de estudio</li>
                        <li>Exámenes simulacro</li>
                        <li>Calendario de estudios</li>
                        <li>Clases grabadas</li>
                        <li>Resúmenes</li>
                        <li>Flashcards</li>
                        <li>Ejercicios</li>
                        <li>Tips</li>
                    </ul>
                </div>


                <h5 class="w-100 text-center mt-3">Clases en vivo</h5>
                <ul class="w-75 mx-auto">
                    <li>Clases en vivo del 1 al 16 de abril del 2023 (periodo vacacional de semana santa).</li>
                    <li>Horario tentativo: 9:00 - 15:00 hrs.</li>
                    <li>Modalidad en línea.</li>
                    <li>Todas las clases quedan grabadas para poderlas ver todas las veces que quieras.</li>
                </ul>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-medprep w-25 ml-auto" data-dismiss="modal">Cerrar</button>
            </div>
        </div>
    </div>
</div>
<!-- MODAL MEDPREP PRO-->
