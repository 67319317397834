import { Component, OnInit, AfterViewInit, AfterContentInit, AfterContentChecked } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ServicioUsuario } from 'src/app/services/usuario.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-prices',
  templateUrl: './prices.component.html',
  styleUrls: ['./prices.component.sass', './prices.component.css']
})
export class PricesComponent implements OnInit, AfterContentChecked {

  public identity;
  public plan;
  constructor(
    private _userService: ServicioUsuario,
    private _router: Router,
    private _route: ActivatedRoute,
  ) {
    this.identity = this._userService.getIdentity();
    this.plan = this.identity ? this.identity.cuenta[0].tipo : '';

  }

  ngAfterContentChecked(): void {
    this.scrollAPlanPro();
  }

  ngOnInit(): void {
    if (environment.production) {
      fbq('track', 'ViewContent'); // Ver contenido
    }
    if (this.identity && this.identity != undefined) {

      switch (this.plan) {
        case 'Gratis':
          $("#boton1").prop('disabled', true);
          $("#boton1").html('Ya cuentas con este plan');
          break;
        case 'Medprep+':
          $("#boton1").prop('disabled', true);
          $("#botonPlus").prop('disabled', true);
          $("#botonPlus").html('Ya cuentas con este plan');
          break;
        case 'Medprep Pro':
          $("#boton1").prop('disabled', true);
          $("#botonPlus").prop('disabled', true);
          $("#botonPro").prop('disabled', true);
          $("#botonPro").html('Ya cuentas con este plan');
          break;
      }

    }
  }

  scrollAPlanPro(): void {
    let whatIs = document.getElementById("pro");
    whatIs.scrollIntoView({ behavior: "smooth" });
  }

  comprar(curso) {
    if (environment.production) {
      fbq('track', 'InitiateCheckout'); //Iniciar pago
    }
    localStorage.setItem("plan", curso);
    this._router.navigate(['/payment/checkout', curso]);
  }
}
