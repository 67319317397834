import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Usuario } from '../models/usuario';
import { GLOBAL } from './global';
import { RUTAS } from './enums/rutas.enum';

@Injectable()
export class ServicioResumen {
    public url: string;

    constructor(
        public _http: HttpClient
    ) {
        this.url = GLOBAL.url + RUTAS.RESUMEN;
    }

    obtenerResumen(tema: String): Observable<any> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');

        return this._http.get(this.url + 'obtener-resumen/' + tema, { headers: headers });
    }

    obtenerResumenPorMateria(materia: String): Observable<any> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');

        return this._http.get(this.url + 'obtener-resumen-por-materia/' + materia, { headers: headers });
    }

    borrarResumen(id: String): Observable<any> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');

        return this._http.delete(this.url + 'borrar-resumen/' + id, { headers: headers });
    }

    agregarResumen(tema, archivos, token): Observable<any> {
        let headers = new HttpHeaders().set('Authorization', token);
        const fd = new FormData();

        if (Array.isArray(archivos)) {
            for (let i = 0; i < archivos.length; i++) {
                if (archivos != null) {
                    fd.append('imagen', archivos[i]);
                }
            }
            fd.append('tema', tema);
        } else {
            return;
        }
        return this._http.post(this.url + 'guardar-resumen', fd, { headers: headers });
    }

}
