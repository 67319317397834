<div class="container">

    <section class="w-25">
        <h5 class="w-100 text-center">Mapa de aciertos</h5>
        <div class="mapa-resultados mr-2 mt-1 text-center pregunta-correcta rounded"><a (click)="irAPregunta()" class="centrar-contenido pregunta-link h-100">1</a></div>
        <div class="mapa-resultados mr-2 mt-1 text-center pregunta-correcta"><a (click)="irAPregunta()" class="centrar-contenido pregunta-link h-100">2</a></div>
        <div class="mapa-resultados mr-2 mt-1 text-center pregunta-incorrecta"><a (click)="irAPregunta()" class="centrar-contenido pregunta-link h-100">3</a></div>
    </section>

</div>
